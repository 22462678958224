.adaptive-example {
    margin-top: 23px;
}

.adaptive-example__card {
    border-radius: 13px;
    border: 1px solid var(--light-grey-border);
    background: var(--website-background);

    box-shadow: 2px 5px 9.7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    max-width: 182px;
    max-height: 345px;
    padding: 17px 13px;
    box-sizing: border-box;
}

.adaptive-example__card__img {
    height: 168px;
    object-fit: cover;
    margin-top: 15px;
    width: 153px;
}

.adaptive-example__card__header-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.adaptive-example__card__header-box__island {
    width: 60px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--footer-border);
}

.adaptive-example__card__info {
    margin-top: 11px;

}

.adaptive-example__card__info__price {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-top: 15px;
}

.adaptive-example__card__info_bottom {
    max-width: 121px;
    width: 100%;
}

.adaptive-example__card__info_bottom__line-1 {
    width: 100%;
    height: 7px;
    border-radius: 10px;
    background: var(--footer-border);
}

.adaptive-example__card__info_bottom__line-2 {
    max-width: 85px;
    height: 7px;
    border-radius: 10px;
    background: var(--footer-border);
    margin-top: 6px;
}

.adaptive-example__text {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    margin-top: 28px;
    max-width: 186px;
}