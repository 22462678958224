@font-face {
    font-family: 'Gorizont';
    src: url('ALSGorizont-MediumExpanded.eot');
    src: url('ALSGorizont-MediumExpanded.eot?#iefix') format('embedded-opentype'),
        url('ALSGorizont-MediumExpanded.woff2') format('woff2'),
        url('ALSGorizont-MediumExpanded.woff') format('woff'),
        url('ALSGorizont-MediumExpanded.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gorizont';
    src: url('ALSGorizont-RegularExpanded.eot');
    src: url('ALSGorizont-RegularExpanded.eot?#iefix') format('embedded-opentype'),
        url('ALSGorizont-RegularExpanded.woff2') format('woff2'),
        url('ALSGorizont-RegularExpanded.woff') format('woff'),
        url('ALSGorizont-RegularExpanded.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gorizont';
    src: url('ALSGorizont-BoldExpanded.eot');
    src: url('ALSGorizont-BoldExpanded.eot?#iefix') format('embedded-opentype'),
        url('ALSGorizont-BoldExpanded.woff2') format('woff2'),
        url('ALSGorizont-BoldExpanded.woff') format('woff'),
        url('ALSGorizont-BoldExpanded.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

