.auth-input {
  width: 100%;
  height: 84px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  border: 2px solid var(--neutral-active);
  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  padding: 0 23px;
  transition: all 0.2s ease-in-out;
}

.auth-input_inactive {
  border-color: #4200FF;
  transition: all 0.2s linear;
}

.auth-input_valid {
  border-color: var(--partners-second-card-border);
  transition: all 0.2s linear;
}

.auth-input_not-valid {
  border-color: #FF0000 !important;
  transition: all 0.2s linear;
}

.auth-input__icon {
  max-width: 35px;
  height: 39px;
}

.auth-input__input {
  width: 100%;
  color: var(--neutral-active);
  font-family: 'ALS Hauss';
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.auth-input__input::placeholder {
  color: var(--neutral-active);
  opacity: 0.3;
}

@media (max-width: 780px) {
  .auth-input {
    height: 55px;
    gap: 10px;
    border-radius: 50px;
    border-color: var(--neutral-active);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0 15px;
  }

  .auth-input__icon {
    max-width: 23px;
    height: 25px;
  }

  .auth-input__input {
    font-size: 17px;
  }

  .auth-input_inactive {
    border-color: #4200FF;
    transition: all 0.2s linear;
  }

  .auth-input_valid {
    border-color: var(--partners-second-card-border);
    transition: all 0.2s linear;
  }

  .auth-input_not-valid {
    border-color: #FF0000 !important;
    transition: all 0.2s linear;
  }
}