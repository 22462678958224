.create-with-ai {
  background-color: var(--website-secondary-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 89px 0px 56px 0px;
  /* max-width: 1920px; */
  overflow: hidden;
}

input[type="file"] {
  cursor: pointer;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.create-with-ai__title {
  max-width: 712px;
  text-align: center;
  font-size: 77.698px;
  font-style: normal;
  font-weight: 750;
  line-height: 89px;
  position: relative;
  z-index: 3;
}

.create-with-ai__title__ai {
  color: var(--primary-text);
  font-size: 77.698px;
  font-style: normal;
  font-weight: 750;
  line-height: 89px;
  border-radius: 26px;
  background: linear-gradient(180deg,
      var(--primary-text-background-gradient-first) 0%,
      var(--primary-text-background-gradient-second) 100%);
  padding: 16px;
  margin-left: 20px;
  position: relative;
}

.create-with-ai__title__ai__sparkle {
  position: absolute;
  width: 34px;
  height: 33.915px;
  top: -40px;
  left: 78%;
  display: block;
}

.create-with-ai__title__ai__sparkle_mobile {
  display: none;
}

.create-with-ai__img-box {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: 122px;
  max-width: 1200px;
  position: relative;
  z-index: 2;
  transform: translate3d(0, 0, 1)
}

.create-with-ai__img {
  position: relative;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  will-change: transform, opacity;
}

.create-with-ai__img_type_create-with-ai-bg {
  width: 57.5%;
  position: absolute;
  top: -12%;
  left: -5.25%;
  z-index: 0;
}

.create-with-ai__img_type_create-with-ai-artificial {
  width: 51.08%;
  left: 32.67%;
  z-index: 2;
}

.create-with-ai__img_type_create-with-ai-intelligence {
  width: 45.83%;
  left: 2.92%;
  bottom: 1.3%;
  z-index: 3;
}

.create-with-ai__img_type_create-with-ai-smile {
  width: 40.33%;
  position: absolute;

  left: 15%;
  bottom: 11.03%;
  z-index: 1;
}

.create-with-ai__subtitle {
  text-align: center;
  opacity: 47%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  max-width: 402px;
}

.create-with-ai__button {
  color: var(--neutral-active);
  border-radius: 160px;
  border: 2px solid var(--neutral-active);
  display: inline-flex;
  text-align: center;
  padding: 18px 22px;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  position: relative;
  z-index: 1;
  width: 350px;
  height: 75px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.create-with-ai__wrapper-button {
  position: absolute;
  left: -2px;
  top: -14px;
  opacity: 0;
  z-index: 6;
  cursor: pointer !important;
  width: 100%;
  height: 100%;
  padding: 0;
}

input#file-upload-button {
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.create-with-ai__button__magic {
  position: absolute;
  left: 120px;
  bottom: -13.069px;
  color: var(--primary-text);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;

  border-radius: 3.96px 3.96px 0px 0px;
  background: var(--steps-color-accent);

  display: flex;
  padding: 8px 8px 4px 9px;
  justify-content: center;
  align-items: center;
  z-index: 5;

  cursor: pointer;
}

.create-with-ai__input-box {
  /* display: flex;
  height: 88px;
  margin-top: 16px;
  gap: 7px; */
  /* width: 100%; */
}

.head-banner__button_disabled {
  user-select: none;
  pointer-events: none;
}

.create-with-ai__input-box input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Gorizont', sans-serif;
  -webkit-box-shadow: 0 0 0 500px var(--website-secondary-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website-secondary-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

.create-with-ai__input-box .head-banner__button {
  max-width: 231px;
  width: 100%;
  padding: 0 39px;
}

.create-with-ai__input-box .head-banner__input-box {
  margin: 0;
  padding-top: 23px;
  grid-template-columns: minmax(0, 424px) 1fr;
  max-width: 424px;
  border: 2px solid var(--neutral-active);
}

.create-with-ai__example {
  display: flex;
  gap: 45px;
  padding: 55px 100px 31px 100px;
  width: 110vw;
  box-sizing: border-box;
  overflow-x: scroll;
  transition: all 5s step-end;
  max-width: 1500px;
}

.create-with-ai__example::-webkit-scrollbar {
  color: gray;
}

.add-krest {
  width: 33px;
  height: 33px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .create-with-ai {
    padding: 38px 16px;
    box-sizing: border-box;
  }

  .create-with-ai__title {
    font-size: 36.467px;
    font-style: normal;
    font-weight: 750;
    line-height: 41.772px;
    /* 114.545% */
  }

  .create-with-ai__title__ai {
    padding: 7px;
    border-radius: 12.203px;
    font-size: 36.467px;
    font-style: normal;
    font-weight: 750;
    line-height: 41.772px;
    /* 114.545% */
  }

  .create-with-ai__title__ai__sparkle {
    display: none;
  }

  .create-with-ai__title__ai__sparkle_mobile {
    position: absolute;
    width: 34px;
    height: 33.915px;
    top: -19px;
    left: 56%;
    display: block;
  }

  .create-with-ai__subtitle {
    max-width: 252px;
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 128.571% */
  }

  .create-with-ai__example {
    overflow-x: scroll;
  }
}

@media (max-width: 600px) {
  .create-with-ai__img-box {
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  .create-with-ai__img_type_bg {
    width: 78.5%;
    top: 0;
    left: -12.05%;
  }

  .create-with-ai__img_type_create-with-ai-artificial {
    width: 75.38%;
    left: 16.67%;
  }

  .create-with-ai__img_type_create-with-ai-intelligence {
    width: 67.69%;
    left: -27.5%;
    bottom: 1%;
  }

  .create-with-ai__img_type_create-with-ai-smile {
    width: 48.6%;
    left: 2.31%;
    bottom: 15.53%;
  }

  .create-with-ai__input-box {
    flex-direction: column;
    margin-top: 37px;
    height: unset;
  }

  .create-with-ai__input-box .head-banner__button {
    max-width: 100%;
    padding: 10px 22px;
  }

  .create-with-ai__input-box .head-banner__input-box {
    padding: 10px 22px;
    width: 100vw;
    max-width: 342.588px;
    height: 64.116px;
  }

  .create-with-ai__img_type_create-with-ai-bg {
    width: 80%;
    left: -14%;
    top: 2%;
  }

  .create-with-ai__input-box {
    /* display: flex;
    height: 88px;
    margin-top: 16px;
    gap: 7px; */
    width: 100%;
  }
}