.magnati {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: var(--website-secondary-background);
  box-sizing: border-box;
  padding: 115px 16px 141px;

  position: relative;
}

.magnati__img {
  width: 530px;
}

.magnati__title {
  /* max-width: 700px; */
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-top: 20px;
}

.magnati__title_red {
  color: var(--magnati-accent-color);
}

.magnati__subtitle {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  /* font-family: 'FS Joey'; */
}

.magnati__img-block {
  width: 100%;
  max-width: 1548px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 32px;
}

.magnati__logo {
  width: 27%;
  max-width: 418px;
  aspect-ratio: 1/1;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: relative;
  z-index: 2;
}

.magnati__anim-block {
  width: 100%;
  aspect-ratio: 75/18;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -9.88%;
  z-index: 1;
}

.magnati__ellipse-label {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 550;
  line-height: 228%;
  position: absolute;
}

.magnati__ellipse {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  border: 21px solid var(--magnati-accent-color);
  box-sizing: border-box;
  will-change: transform, opacity;
}

.magnati__word {
  /* top: 65px; */
  z-index: 1000;
  height: 100%;
  max-height: 150px;
  position: absolute;
  border-radius: 50%;
}

.magnati__word_pc {
  display: block;
}

.magnati__word_mobile {
  display: none;
}





@media (max-width: 1300px) {
  .magnati__word {
    top: 23%;
    margin-left: 120px;
  }

  .magnati__word_pc svg {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1220px) {
  .magnati__word {
    margin-left: 0px;
  }

  .magnati__ellipse-label {
    font-size: 20px;
  }

  .magnati__word_pc {
    display: none;
  }

  .magnati__word_mobile {
    display: block;
  }
}



@media (max-width: 1000px) {
  .magnati {
    padding: 68px 28px;
  }

  .magnati__title {
    font-size: 28px;
    line-height: 25.58px;
    margin-top: 20px;
  }

  .magnati__subtitle {
    font-size: 16px;
    line-height: 140%;
  }

  .magnati__img-block {
    width: calc(100% + 32px);
    margin-top: 48px;
  }

  .magnati__ellipse-label {
    font-size: 15px;
  }

  .magnati__ellipse {
    border-width: 14px;
  }

  .magnati__word {
    top: 17%
  }

}

@media (max-width: 800px) {
  .magnati__word {
    top: 23%;
  }

  .magnati__img-block {
    width: 250%;
    max-width: 832px;
  }

  .magnati__word_mobile {
    margin-top: -30px;
  }

  .magnati__img {
    width: 257px;
  }
}