.signup__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.signup__title {
  /* display: flex; */
  align-items: center;
  text-align: center;
  font-size: 82px;
  font-style: normal;
  font-weight: 750;
  line-height: 118%;
  gap: 20px;
  position: relative;

  width: 100%;
}

.signup__smile-icon {
  /* width: 83px; */
  transform: rotate(-90deg);
}

.signup__smile-icon_type_desc {
  display: inline-flex;
  position: relative;
  top: 12px;
}

.signup__smile-icon_type_mobile {
  display: none;
}

.signup__smile-icon-fill {
  fill: var(--primary-text-background);
}

.signup__text {
  text-align: center;
  font-family: 'ALS Hauss';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* display: ruby; */
}

.signup__text-box {
  margin-top: 24px;
  /* display: grid; */
  /* grid-template-columns: 24px 1fr; */
  /* display: flex; */
  position: relative;
  max-width: 100%;
  column-gap: 8px;
  align-items: center;
  width: 100%;
}

.signup__text-icon-box_mobile {
  display: none;


  display: inline-flex;
  position: relative;
  top: 4px;
  right: 4px;

  border-radius: 50%;
  border: 1px solid var(--neutral-active);
}

.signup__text-icon-box {
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 50%;
  width: 24px;
  aspect-ratio: 1/1;
  border: 1px solid var(--neutral-active);

  display: none;
}

.signup__text-icon {
  opacity: 0;
  /* position: absolute; */
  top: 0px;
  left: 0px;
  transition: all ease-in-out 0.2s;
}

.signup__text-icon_full {
  transition: all ease-in-out 0.2s;
  opacity: 1;
}

.signup__text-link {
  text-decoration-line: underline;
}

.signup__form {
  width: 100%;
  max-width: 516px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 84px;
}

.signup__inputs-box {
  width: 100%;
  display: grid;
  grid-template-columns: 172px 1fr;
  gap: 14px;
}

.signup__submit-btn {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: var(--primary-text-background);
  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
}

.signup__submit-btn:hover {
  background: var(--primary-extra-button);
}

.signup__submit-btn:active {
  background: var(--primary-active);
}

.signup__submit-btn_disabled {
  background: var(--primary-disabled);
  user-select: none;
  pointer-events: none;
}

.signup__btn-text {
  color: var(--primary-text);
  text-align: center;
  font-family: 'ALS Hauss';
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 171%;
}

.signup__preloader {
  top: 0;
  z-index: 1;
  background-color: var(--global-bg) ;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
}

.signup__preloader-glob-box {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signup__preloader-glob {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  animation: move 10s linear infinite;

}

.signup__preloader-spy {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: rotate 10s linear infinite;
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 1090px) {
  .signup__text {
    line-height: 110%;
  }

  .signup__text-icon-box {
    /* margin: 24px 0 0 0; */
    width: 20px;
    height: 20px;
  }

  .signup__text-icon {
    /* margin: 24px 0 0 0; */
    width: 20px;
    height: 20px;
  }

  .signup__text-icon-box_mobile {
    top: 0;
  }
}

@media (max-width: 1000px) {
  .signup__text-icon-box {
    margin-right: 24px 0 0 0;
  }

  .signup__title {
    font-size: 54px;
  }

  .signup__text {
    margin-top: 16px;
  }

  .signup__form {
    gap: 20px;
    margin-top: 32px;
  }

  .signup__smile-icon_type_desc {
    display: inline-flex;
    position: relative;
    top: 22px;
  }
}

@media (max-width: 960px) {
  .signup__text-box {
    grid-template-columns: 18px 1fr;
  }

  .signup__text-icon-box {
    width: 16px;
    height: 16px;
  }

  .signup__text-icon {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 780px) {
  .signup__title {
    font-size: 30px;
    align-items: flex-end;
    gap: 12px;
  }

  .signup__smile-icon {
    width: 39px;
  }

  .signup__smile-icon_type_desc {
    display: none;
  }

  .signup__smile-icon_type_mobile {
    display: inline-flex;
    position: relative;
    top: 6px;
  }

  .signup__text {
    font-size: 14px;
    /* padding: 0 32px; */
    margin-top: 12px;
    text-align: center;
    line-height: 22px;
    /* max-width: 80%; */
    margin: 4px auto 0 auto;
  }

  .signup__inputs-box {
    grid-template-columns: 112px 1fr;
    gap: 8px;
  }

  .signup__submit-btn {
    height: 55px;
    border-radius: 34px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 20px;
  }

  .signup__btn-text {
    font-size: 17px;
  }

  .signup__text-box {
    display: block;
  }

  .signup__preloader-glob-box {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .signup__text-icon-box {
    display: none;
  }

  .signup__text-icon-box_mobile {
    display: inline-flex;
    position: relative;
    top: 4px;
    right: 4px;

    border-radius: 50%;
    border: 1px solid var(--neutral-active);
  }
}

@media (max-width:408px) {
  .signup__smile-icon_bg {
    position: absolute;
    top: 64px;
    left: 63%;
  }
}

@media (max-width: 391px) {
  .signup__smile-icon_en {
    position: absolute !important;
    top: 65px !important;
    left: 64% !important;
  }
}

@media (max-width: 380px) {
  .signup__smile-icon {
    width: 30px;
    top: 11px
  }

  .signup__smile-icon_en {
    position: absolute !important;
    top: 68px !important;
    left: 66% !important;
  }

  .signup__smile-icon_bg {
    position: absolute;
    top: 138px;
    left: 64%;
  }

  .signup__smile-icon_bg {
    position: absolute;
    top: 68px;
    left: 65%;
  }
}