.partners-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--website-secondary-background);
  box-sizing: border-box;
  padding: 120px 32px;
}

.partners-overview__title {
  max-width: 837px;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 107%;
}

.partners-overview__text {
  max-width: 896px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
  padding-top: 12px;
}

.partners-overview__cards-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.partners-overview__list {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  gap: 30px;
  margin-top: 40px;
}

.partners-overview__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.partners-overview__card-img {
  width: 100%;
  aspect-ratio: 7/3;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}

.partners-overview__card-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  padding-top: 24px;
}

@media (max-width: 1170px) {
  .partners-overview__list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 780px) {
  .partners-overview {
    padding: 60px 20px 64px;
  }

  .partners-overview__title {
    font-size: 28px;
    line-height: 114%;
  }

  .partners-overview__text {
    font-size: 16px;
    line-height: 150%;
    padding-top: 16px;
  }

  .partners-overview__cards-block {
    margin-top: 100px;
  }

  .partners-overview__list {
    gap: 40px;
    margin-top: 24px;
  }

  .partners-overview__card-text {
    font-size: 16px;
  }
}
