.review-slide {
  width: 100%;
  display: grid;
  grid-template-columns: 490px 1fr;
  grid-template-rows: max-content;
  align-items: center;
  padding-left: 219px;
  box-sizing: border-box;
  overflow: hidden;
}

.review-slide__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.review-slide__info-head {
  display: grid;
  grid-template-columns: 54px 1fr;
  column-gap: 14px;
  grid-template-rows: max-content;
  width: 100%;
}

.review-slide__info-avatar {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-slide__info-avatar-logo {
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.review-slide__info-names {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
}

.review-slide__info-name {
  font-size: 23.101px;
  font-style: normal;
  font-weight: 700;
}

.review-slide__info-stars {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review-slide__info-star {
  width: 34px;
  height: 34px;
}

.review-slide__info-star-fill {
  fill: #f1c142;
}

.review-slide__info-site {
  font-size: 23.922px;
  font-style: normal;
  font-weight: 400;
}

.review-slide__description {
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 32px;
  margin: 40px 0 0;
  white-space: pre-line;
}

.review-slide__logo {
  width: fit-content;
  max-width: 230px;
  max-height: 128px;
  object-fit: contain;
  margin: 75px 0 0;
  user-select: none;
  pointer-events: none;
}

.review-slide__img-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.review-slide__img-box_type_1 {
  padding: 6.85% 0 0 13.4%;
}

.review-slide__img-box_type_2 {
  padding: 6.29% 0 0 5.7%;
}

.review-slide__img-box_type_3 {
  padding: 5.83% 0 0 14.15%;
}

.review-slide__img-box_type_4 {
  padding: 6.94% 0 0 13.5%;
}

.review-slide__img-box_type_5 {
  padding: 7.77% 0 0 16.09%;
}

.review-slide__img-box_type_6 {
  padding: 7.12% 0 0 6.29%;
}

.review-slide__img-box_type_7 {
  padding: 6.66% 0 0 12.02%;
}

.review-slide__img-box_type_8 {
  padding: 8.33% 0 0 6.47%;
}

.review-slide__img-box_type_9 {
  padding: 7.77% 0 0 14.06%;
}

.review-slide__img-box_type_10 {
  padding: 8.23% 0 0 13.97%;
}

.review-slide__img-pc {
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 0;
  will-change: opacity, transform;
}

.review-slide__img-mobile {
  width: 28.4%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  will-change: opacity, transform;
}

.review-slide__img-mobile_1 {
  margin-top: -80px;
}

.review-slide__img-mobile_3 {
  height: 120%;
  margin-top: -100px;
}

.review-slide__img-mobile_4 {
  margin-top: -60px;
}

.review-slide__img-mobile_5 {
  margin-top: -30px;
}

.review-slide__img-mobile_6 {
  margin-top: -90px;
}

.review-slide__img-mobile_9 {
  margin-top: -40px;
}

@media (max-width: 1440px) {
  .review-slide {
    padding-left: 80px;
  }
}

@media (max-width: 1300px) {
  .review-slide {
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;
  }
}

@media (max-width: 1050px) {
  .review-slide__description {
    font-size: 16px;
    line-height: 120%;
  }

  .review-slide {
    grid-template-columns: 3fr 5fr;
    column-gap: 80px;
  }

  .review-slide__info-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .review-slide__info-star {
    width: 24px;
    height: 24px;
  }

  .review-slide__info-site {
    font-size: 16px;
  }
}

@media (max-width: 880px) {
  .review-slide {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    row-gap: 18px;
  }

  .review-slide__info {
    padding-right: 80px;
    box-sizing: border-box;
  }
}

@media (max-width: 780px) {
  .review-slide {
    padding: 0;
  }

  .review-slide__info {
    padding: 0 48px 0 24px;
    box-sizing: border-box;
  }

  .review-slide__img-box {
    width: 118%;
    max-width: 700px;
    margin: 0 auto;
  }

  .review-slide__info-head {
    grid-template-columns: 30px 1fr;
  }

  .review-slide__info-avatar {
    width: 30px;
    height: 30px;
  }

  .review-slide__info-names {
    gap: 8px;
  }

  .review-slide__info-name {
    font-size: 13.074px;
    font-style: normal;
    font-weight: 900;
  }

  .review-slide__info-star {
    width: 20px;
    height: 20px;
  }

  .review-slide__info-site {
    font-size: 13.538px;
    font-style: normal;
    font-weight: 400;
  }

  .review-slide__description {
    margin: 18px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    word-spacing: 2px;
  }

  .review-slide__logo {
    max-width: 163px;
    max-height: 67px;
    margin: 18px 0 0;
  }
}

@media (max-width:500px) {
  .review-slide__img-mobile_1 {
    margin-top: -50px;
  }

  .review-slide__img-mobile_3 {
    margin-top: -65px;
  }

  .review-slide__img-mobile_6 {
    margin-top: -58px;
  }

  .review-slide__img-mobile_9 {
    margin-top: -12px;
  }
}