.depart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--website-secondary-background);
    padding: 97px 80px 66px 80px;
    width: 100%;
    box-sizing: border-box;
}

.depart__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
}

.depart__cards-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 101px;
    row-gap: 118px;
    width: 100%;
    max-width: 1351px;
    margin-top: 70px;
}

.depart__card {
    border-radius: 15px;
    background: var(--website-background);
    padding: 14px 20px;
    position: relative;
}

.depart__card_icon-and-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.depart__card__icon {
    border-radius: 12.361px;
    border: 1px solid var(--website-secondary-background);
    background: var(--website-background);
    box-sizing: border-box;
    padding: 3px 4px;
    width: 47.862px;
    height: 47.862px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.depart__card__text {
    max-width: 190px;
    font-size: 21px;
    font-style: normal;
    font-weight: 750;
    line-height: 24px;
    /* 114.286% */
}

.depart__card__img {
    position: absolute;
    max-width: 253px;
    top: -30px;
    right: -81px;
}

.depart__button {
    color: var(--primary-text);
    border-radius: 140px;
    background: var(--primary-active);
    display: flex;
    width: 294px;
    padding: 18.784px 27.08px 18.784px 23.93px;
    justify-content: center;
    align-items: center;
    gap: 9.72px;
    margin-top: 88px;
}

@media (max-width:1420px) {
    .depart {
        padding: 59px 14px 40px 14px;
    }

    .depart__cards-box {
        row-gap: 52px;
        column-gap: 35px;
    }

    .depart__card__text {
        font-size: 9.465px;
        font-style: normal;
        font-weight: 750;
        line-height: normal;
    }

    .depart__card__img {
        max-width: 150px;
        right: -24px;
        top: -25px;
    }
}

@media (max-width:960px) {
    .depart__cards-box {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 52px;
        column-gap: 35px;
    }

}

@media (max-width:650px) {
    .depart__title {
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        /* 31.2px */
    }

    .depart__card__img {
        max-width: 80px;
        right: -15px;
        top: -20px;
    }

    .depart__cards-box {
        margin-top: 40px;
    }

    .depart__button {
        margin-top: 42px;
    }

    .depart__card__icon {
        width: 20.591px;
        height: 20.591px;
        border-radius: 5.318px;
        padding: 0;
    }
}