.google-example {
    margin-top: 18px;
}

.google-example__card {
    border-radius: 13px;
    border: 1px solid var(--light-grey-border);
    background: var(--website-background);

    box-shadow: 2px 5px 9.7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    max-width: 195px;
    max-height: 300px;
    padding: 11.5px 0px 21px 9px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.google-example__photo-card__img {
    width: 124px;
    height: 148px;
    object-fit: cover;
}

.google-example__card__logo {
    max-width: 70px;
    margin: 0 auto;
}

.google-example__card__search {
    width: 100%;
    max-width: 179px;
}

.google-example__text {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    margin-top: 11px;
    max-width: 186px;
}

.google__circle {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -20px;
    top: 140px;
}