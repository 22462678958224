.about-us {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.about-us__box {
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    width: 100%;
   
    box-sizing: border-box;
    align-items: center;

    overflow: hidden;
}