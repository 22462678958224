.blog {
    width: 100%;
    max-width: 1900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 40px 80px 109px 80px;
}

.blog .mini-preloader {
    justify-content: center;
    align-items: center;
}

.blog .subscribe__box {
    margin-top: 40px;
    border-top: 1px solid var(--article-border-color);
}

.number {
    position: relative;
    bottom: unset !important;
}

.number_duble {
    position: absolute;
    top: 0;
    pointer-events: none;
    left: 10px;
    top: 5px;
}

/* .outer {
    background-color: #03A9F4;
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 50% 0, 50% 50%, 50% 50%);
    animation: xxx 3s linear forwards;
}

.inner {
    transform: scale(0.95);
    background-color: var(--website-background);
    width: 25px;
    height: 25px;
    aspect-ratio: 1/1;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.outer_animation {
    animation: xxx 3s linear forwards;
} */

@keyframes xxx {
    12.5% {
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 0 0, 50% 50%, 50% 50%);
    }

    25% {
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 50%, 0 50%, 50% 50%, 50% 50%);
    }

    37.5% {
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 100%, 0 100%, 50% 50%, 50% 50%);
    }

    50% {
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 50%, 50% 50%);
    }

    62.5% {
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%, 50% 50%);
    }

    75% {
        clip-path: polygon(50% 0%, 100% 0, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 50% 50%, 50% 50%);
    }

    87.5% {
        clip-path: polygon(50% 0%, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 50% 50%, 50% 50%);
    }

    100% {
        clip-path: polygon(50% 0%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 50%, 50% 50%);
    }
}

.autoplay-progress__numbers-box {
    display: flex;
    margin: 40px 20px 0 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.autoplay-progress__numbers {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.autoplay-progress {
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #4FC865;
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #4FC865;
    fill: none;

    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.autoplay-progress__number {
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.blog__title {
    font-size: 74px;
    font-style: normal;
    font-weight: 750;
    line-height: 88px;
}

.blog__main-grid {
    display: grid;
    grid-template-columns: minmax(0, 426px) minmax(0, 535px) minmax(0, 426px);
    /* grid-template-rows: 274.95px 52px 274.95px 52px;
    width: 100%;
    grid-template-areas:
        'card1 card2 card3'
        '. card2 .'
        'card4 card2 card5'
        '. card2 .'; */
    max-width: 1500px;
    /* row-gap: 40px; */
    column-gap: 46px;
    margin-top: 40px;
}

.blog__main-grid_small-card {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    /* gap: 46px; */
}

.blog__main-grid__img {
    object-fit: contain;
    width: 100%;
}

.blog__card-swiper-wrapper {
    display: grid;
    grid-template-rows: 1fr minmax(min-content, max-content);
}

/* .blog__main-grid .swiper-wrapper { */
/* height: unset; */
/* min-height: 900px; */
/* } */

.main-card-swiper {
    width: 100%;
    height: 100%;
}

.main-card-swiper .swiper-pagination-bullet {
    width: 16px;
    height: 16px;

    transform: scale(0.95);
    background-color: var(--website-background) !important;
    width: 25px;
    height: 25px;
    aspect-ratio: 1/1;
    border-radius: 50%;

    color: black !important;
    opacity: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-card-swiper .swiper-pagination-bullet-active {
    width: 22px;
    height: 22px;

    background-color: #4FC865 !important;
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 50% 0, 50% 50%, 50% 50%);
    animation: xxx 3s linear forwards;

    display: flex;
    align-items: center;
    justify-content: center;
}

.main-card-swiper .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 35px;

    position: relative !important;
    bottom: unset !important;
    top: unset !important;
    flex-wrap: wrap;
}

.blog__main-grid__type {
    color: var(--primary-text);
    font-size: 14.606px;
    font-style: normal;
    font-weight: 750;
    line-height: 18.257px;

    display: flex;
    padding: 6.086px 24.951px;
    justify-content: center;
    align-items: center;
    gap: 6.086px;

    border-radius: 25.56px;
    margin-top: 20px;
    width: fit-content;
}

.blog__main-grid__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* max-width: 300px; */
    width: 100%;
    margin-top: 8px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog__main-grid__title_swiper-card {
    position: absolute;
    top: 70%;
    z-index: 9;
    padding: 0 32px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.blog__main-grid__title_swiper-card .blog__main-grid__title {
    color: #fff;
    font-size: 26.72px;
    line-height: 31.13px;
}

.blog__main-grid__title_swiper-card .blog__main-grid__like {
    color: rgba(255, 255, 255, 0.6);
}

.blog__main-grid__img__slide {
    position: relative;
    z-index: 8;
}

.blog__title_starting {
    align-self: flex-start;
    padding-top: 81px;
}

.blog__main-grid_starting {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 1500px;
    row-gap: 40px;
    column-gap: 46px;
    margin-top: 40px;

    min-height: 900px;
}

.blog__main-grid_starting__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;
    margin-top: 12px;
    /* 24.465px */
}

.blog__main-grid_starting__subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 116.5%;
    /* 24.465px */
    margin-top: 14px;
}

/* .blog__main-grid_starting__img {
    height: 638px;
} */

.blog__main-grid__time {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.3;
}

.blog__main-grid__like {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3px;
    pointer-events: stroke;
}

.blog__main-grid__like-box {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--neutral-active);
    margin-top: -10px;
    margin-right: -10px;
    padding: 10px;
    box-sizing: border-box;
}

.blog__main-grid__like-box_big {
    margin-left: -20px;
}

.blog__main-grid__time-and-like {
    display: grid;
    justify-content: space-between;
    margin-top: 14px;
    grid-template-columns: 1fr 50px;
}

.subscribe__box {
    border-bottom: 1px solid var(--article-border-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    box-sizing: border-box;
    padding: 60px 0 95px;
}

.search-bar {
    width: 100%;
    margin-top: 86px;
}

.search-bar__form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-color);
    border-radius: 8px;
    border: 1px solid var(--search-border-color);
    box-sizing: border-box;
    padding: 4px 4px 4px 20px;
    transition: all 0.15s ease-in-out;
    position: relative;
}

input {
    outline: none;
}

.search-bar__input {
    width: 100%;
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 21px;
    font-weight: 300;
    line-height: 90%;
    letter-spacing: -0.63px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 14px 6px 14px 86px;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border-bottom: 1px solid rgba(var(--neutral-active), 0.3); */
    background-color: transparent;
    color: var(--neutral-active);
}

.search-bar__input::placeholder {
    opacity: 0.2;
    color: var(--neutral-active);
}

.search-bar__submit-btn {
    width: 62px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: var(--search-btn-bg);

    position: absolute;
    top: 0;
    left: 20px;
}

.search-bar__search-icon {
    width: 26px;
    height: 26px;
}

.search-bar__search-icon-fill {
    fill: var(--accent-color);
}

.follow-social {
    margin-top: 80px;
}

.follow-social__title {
    margin-top: 70px;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
}

.follow-social__grid {
    display: flex;
    gap: 17px;
    margin-top: 30px;
    justify-content: center;
}

.follow-social__icon {
    max-width: 65px;
    max-height: 65px;
    width: 100%;
}

.follow-social__icon-1 {
    margin-top: 45px;
}

.follow-social__icon-3 {
    margin-top: 30px;
}

.follow-social__icon-4 {
    margin-top: 15px;
}

.follow-social__icon-5 {
    margin-top: 35px;
}

.tabs-menu {
    display: flex;
    align-items: center;
    margin-top: 177px;
    flex-wrap: wrap;
}

.tabs {
    color: var(--neutral-active);
    border-radius: 240px;
    border: 3px solid var(--neutral-active);
    display: inline-flex;
    padding: 39.406px 46.055px 39.406px 46.245px;
    align-items: flex-start;

    text-align: center;
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
}

.tabs_smile {
    width: 103.094px;
    height: 103.094px;
}

.tabs_selected {
    border-radius: 240px;
    border: 3px solid var(--primary-active);
    background: var(--primary-active);
    color: var(--primary-text);

}

.blog__main-grid .blog__main-grid__time-and-like {
    margin-top: 8px;
}

.pagination__box {
    display: flex;
    gap: 20px;
    color: var(--neutral-active);
    margin-top: 40px;
}

.pagination__box__page {
    font-size: 25px;
    font-weight: 800;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    color: #35C650;
    border: 1.77px solid #35C650;
    width: 56.59px;
    height: 56.59px;
    border-radius: 50%;
}

.arrow__box {
    box-sizing: border-box;
    border: 1.77px solid #DFE3E8;
    width: 56.59px;
    height: 56.59px;
    border-radius: 50%;

    display: flex;
    padding: 10px 20px;
    align-items: center;
}

.arrow__box_inactive {
    opacity: 50%;
    background-color: #919EAB;
    border: 1.77px solid #919EAB;
}

.arrow-back {
    height: 21.22px;
    width: 13.1px;
    transform: rotate(180deg);
}

.arrow {
    width: 13.1px;
    height: 21.22px;
}

.blog__preloader-gradient {
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;
    background: repeating-linear-gradient(to right,  var(--website-third-background) 0%, hsla(0, 0%, 100%, 0.04) 50%, var(--website-third-background) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    /* margin-top: 8px; */
    border-radius: 6px;
    height: 100%;
    width: 100%;

    min-height: 300px;
    margin-top: 10px;
}

.blog__preloader-gradient_long {
    min-height: 450px;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@media (max-width: 1340px) {
    .blog__main-grid__title_swiper-card .blog__main-grid__title {
        font-size: 23px;
    }
}

@media (max-width: 1200px) {
    .blog__main-grid {
        margin-top: 22px;
        display: grid;
        grid-template-columns: minmax(0, 326px) minmax(0, 735px) minmax(0, 326px);
        /* grid-template-rows: 544px repeat(4, minmax(0, 288px)); */
        width: 100%;
        row-gap: 10px;
        column-gap: 10px;
        grid-template-areas:
            'card2'
            'card4'
            'card3'
            'card5'
            'card1';
    }

    .blog__main-grid__title_swiper-card {
        padding: 0 10px;
        left: 10px;
    }

    .blog__main-grid__title_swiper-card .blog__main-grid__title {
        font-size: 20px;
        line-height: 120%;
    }

    .autoplay-progress__numbers-box {
        margin: 10px 20px 0;
    }
}

@media (max-width: 960px) {
    .blog {
        padding: 22px 16px 81px 16px;
    }

    .blog__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 34px;
        /* 121.429% */
    }

    .blog__main-grid_starting {
        grid-template-columns: 1fr;
    }

    .blog__main-grid {
        margin-top: 22px;
        display: grid;
        grid-template-columns: 1fr;
        /* grid-template-rows: 544px repeat(4, minmax(0, 288px)); */
        width: 100%;
        column-gap: 0;
        grid-template-areas:
            'card2'
            'card4'
            'card3'
            'card5'
            'card1';
    }

    .blog__main-grid_small-card {
        width: 100%;
    }

    .main-card-swiper {
        width: 100%;
    }

    .blog__main-grid__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 750;
        line-height: 16.8px;
        max-width: unset;
    }

    .blog__main-grid__title_swiper-card {
        padding: 0 20px;
        justify-content: flex-start;
    }

    .blog__main-grid__title_swiper-card .blog__main-grid__title {
        font-size: 30px;
    }

    .blog__main-grid__time {
        margin-top: 3.44px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .blog__main-grid__img {
        max-width: unset;
    }

    .subscribe__box {
        padding-bottom: 64px;
    }

    .blog__main-grid_starting__title {
        margin-top: 18px;
    }

    .search-bar {
        margin: 120px 0 0 0;
    }

    .tabs-menu {
        margin-top: 30px;
    }

    .autoplay-progress__numbers-box {
        margin: 0 20px;
    }
}

@media (max-width: 800px) {
    .search-bar__form {
        padding: 2px 2px 2px 16px;
    }

    .search-bar__input {
        font-size: 14px;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -0.42px;
    }

    .search-bar__submit-btn {
        width: 48px;
        height: 32px;
    }

    .search-bar__search-icon {
        width: 18px;
        height: 18px;
    }

    .pagination__box {
        margin-top: 20px;
    }
}

@media (max-width: 430px) {
    .blog__main-grid__title_swiper-card .blog__main-grid__title {
        font-size: 20px;
    }
}