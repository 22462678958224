.mobile-first {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--website-secondary-background);
  padding: 90px 0 0;
}

.mobile-first__content {
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.mobile-first__title {
  font-size: 56px;
  font-style: normal;
  font-weight: 750;
  line-height: 60px;
  text-align: center;
}

.mobile-first__subtitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 16px 0 0;
  max-width: 712px;
}

.mobile-first__img-box {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin-top: -2.92%;
  will-change: opacity, transform;
}

.mobile-first__img-bg {
  width: 100%;
  user-select: none;
  pointer-events: none;
  position: relative;
  z-index: 0;
}

.mobile-first__screens-list {
  width: 52.92%;
  height: 67.26%;
  display: flex;
  position: absolute;
  top: 7.04%;
  z-index: 1;
}

.mobile-first__screen-item {
  width: 43.31%;
  display: flex;
  position: relative;
  will-change: opacity, transform;
}

.mobile-first__screen-item:first-child {
  transform: rotate(-7.169deg);
  top: 10.47%;
  margin-right: -15.49%;
  z-index: 3;
}

.mobile-first__screen-item:nth-child(2) {
  transform: rotate(2.243deg);
  z-index: 2;
}

.mobile-first__screen-item:last-child {
  transform: rotate(9.203deg);
  top: 9.7%;
  margin-left: -14.44%;
  z-index: 1;
}

.mobile-first__screen-img {
  width: 100%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.mobile-first__icons-list {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
}

.mobile-first__icon-item {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: var(--mobile-first-icons-bg);
  border-radius: 26%;
  will-change: opacity, transform;
}

.mobile-first__icon-item:first-child {
  width: 8.33%;
  box-shadow: 0px 4.085px 13.276px 0px var(--mobile-first-icons-shadow);
  top: 14.29%;
  left: 15.97%;
}

.mobile-first__icon-item:nth-child(2) {
  width: 7.22%;
  box-shadow: 0px 3.552px 11.544px 0px var(--mobile-first-icons-shadow);
  top: 33.13%;
  left: 21.18%;
}

.mobile-first__icon-item:nth-child(3) {
  width: 10.56%;
  box-shadow: 0px 5.196px 16.889px var(--mobile-first-icons-shadow);
  top: 46.58%;
  left: 13.54%;
}

.mobile-first__icon-item:nth-child(4) {
  width: 8.47%;
  box-shadow: 0px 4.174px 13.565px 0px var(--mobile-first-icons-shadow);
  top: 24.33%;
  right: 11.94%;
}

.mobile-first__icon-item:nth-child(5) {
  width: 10.56%;
  box-shadow: 0px 5.196px 16.889px var(--mobile-first-icons-shadow);
  top: 36.44%;
  right: 20.49%;
}

.mobile-first__icon-item:nth-child(6) {
  width: 4.79%;
  box-shadow: 0px 2.348px 7.631px 0px var(--mobile-first-icons-shadow);
  top: 48.03%;
  right: 13.06%;
}

.mobile-first__icon-item:last-child {
  width: 5.83%;
  box-shadow: 0px 2.862px 9.303px 0px var(--mobile-first-icons-shadow);
  top: 56.42%;
  right: 19.1%;
}

.mobile-first__icon {
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.mobile-first__icon-item:first-child .mobile-first__icon {
  width: 79.17%;
}

.mobile-first__icon-item:nth-child(2) .mobile-first__icon {
  width: 79.81%;
}

.mobile-first__icon-item:nth-child(3) .mobile-first__icon {
  width: 56.58%;
}

.mobile-first__icon-item:nth-child(4) .mobile-first__icon {
  width: 80.33%;
}

.mobile-first__icon-item:nth-child(5) .mobile-first__icon {
  width: 64.47%;
}

.mobile-first__icon-item:nth-child(6) .mobile-first__icon {
  width: 79.71%;
}

.mobile-first__icon-item:last-child .mobile-first__icon {
  width: 79.76%;
}

@media (max-width: 880px) {
  .mobile-first {
    padding: 60px 0 0;
  }
  .mobile-first__content {
    padding: 0 24px;
    overflow: hidden;
  }
  .mobile-first__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 750;
    line-height: 32px;
  }
  .mobile-first__subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0 0;
  }
}

@media (max-width: 670px) {
  .mobile-first__img-box {
    width: 165%;
    max-width: 620px;
  }
}
