.insta-example {
    margin-top: 74px;
}

.insta-example__card {
    border-radius: 13px;
    border: 1px solid var(--light-grey-border);
    background: var(--website-background);

    box-shadow: 2px 5px 9.7px 0px rgba(0, 0, 0, 0.25);

    height: 100%;
    width: 182px;
    max-height: 216px;
    padding: 12px 0 0 0;

    position: relative;
    z-index: 3;
}

.avatar-insta {
    object-fit: cover;
    width: 27px;
    height: 27px;
}

.insta-example__photo-card__img {
    width: 100%;
    height: 100%;
    max-height: 173px;
    border-radius: 0 0 13px 13px;
    object-fit: cover;
    margin-top: 7px;
}

.insta-example__card__user-info-block {
    display: flex;
    gap: 7px;
    width: 100%;
    margin-left: 11px;
    align-items: center;
}

.insta-example__card__user-info-block__name {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 163.636% */
}

.insta-example__card__user-info-block__status {
    color: var(--light-grey-status);
    font-family: "ALS Hauss";
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 257.143% */
}

.insta-example__card__img-box {
    display: flex;
    overflow: hidden;
    gap: 9px;
    margin-top: 15px;
}


.insta-example__photo-card__info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.insta-example__text {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    margin-top: 13px;
    max-width: 186px;
}

.insta-example__card__logo_shop {
    position: absolute;
    left: 8px;
    bottom: 6px;
}

.insta-example__card__logo_price {
    position: absolute;
    left: 82px;
    bottom: 52px;
}

.insta__circle {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 162px;
    top: 21px;
    z-index: 5;
}