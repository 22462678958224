.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .signup__preloader {
  position: absolute;
  z-index: 200;
  background-color: var(--global-bg) ;
  /* background-color: transparent ; */
}