.facebook-example {
    margin-top: 153px;
}

.facebook-example__card {
    border-radius: 13px;
    border: 1px solid var(--light-grey-border);
    background: var(--website-background);

    box-shadow: 2px 5px 9.7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    max-width: 218px;
    max-height: 243px;
    padding: 11.5px 0px 21px 9px;
    box-sizing: border-box;
    position: relative;
}

.facebook-example__card__user-info-block {
    display: flex;
    gap: 7px;
    width: 100%;
}

.facebook-example__card__user-info-block__name {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.facebook-example__card__user-info-block__status {
    color: var(--light-grey-status);
    font-family: "ALS Hauss";
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 257.143% */
}

.facebook-example__card__user-info-block__dots {
    margin-right: 17px;
}

.facebook-example__card__img-box {
    display: flex;
    overflow: hidden;
    gap: 9px;
    margin-top: 15px;
}

.facebook-example__photo-card__img {
    width: 139px;
    height: 133px;
    object-fit: cover;
}

.facebook-example__photo-card__button {
    display: flex;
    width: 72px;
    height: 20px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid var(--light-grey-border);

    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 200% */
}

.facebook-example__photo-card__price {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 163.636% */
}

.facebook-example__photo-card__info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.facebook-example__text {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    margin-top: 29px;
    max-width: 186px;
}

.facebook__circle {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 137px;
    top: -15px;
}