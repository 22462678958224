.global {
    background: var(--global-bg);
    display: flex;
    flex-direction: column;

    padding: 67px 80px 0 80px;
    position: relative;
    overflow: hidden;
    height: 1080px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.global__title {
    color: var(--primary-text);
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    max-width: 1210px;
}

.global__subtitle-box {
    display: flex;
    gap: 102px;
    margin-top: 32px;
}

.global__subtitle {
    color: var(--primary-text);
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}

.global__subtitle_first {
    max-width: 242px;
}

.global__subtitle_second {
    max-width: 400px;
}

.global__glob-box {
    position: relative;
    width: 140%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.global__stars {
    position: absolute;
    width: 2514px;
    height: 1086.512px;
    top: -26px;
    left: -330px;
}

.global__blur {
    top: 791px;
    left: -266px;
}

.global__glob {
    margin-top: -228px;
    z-index: 5;
}

.global__spy {
    width: 995.331px;
    height: 995.812px;
    z-index: 8;
    position: absolute;
    left: auto;
    top: 96px;

}

@media (max-width:960px) {
    .global {
        padding: 47px 0 0 21px;
        height: 774px;
    }

    .global__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .global__subtitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        line-height: 24px;
        /* 133.333% */

        gap: 12px;
    }

    .global__subtitle-box {
        flex-direction: column;
        gap: 35px;
        margin-top: 18px;
    }

    .global__stars {
        display: none;
    }

    .global__blur {
        display: none;
    }

    .global__glob {
        width: 971px;
        margin-top: -130px;
    }

    .global__spy {
        width: 531px;
        height: 531px;
        top: 42px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}