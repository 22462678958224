.popup {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--website-background);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 36px 60px;
  overflow: scroll;
}

.popup_with-preloader{
  background: var(--global-bg);

}

.popup_with-preloader .popup__container{
  padding: 0;
  max-width: unset;
}

.popup__container::-webkit-scrollbar {
  display: none;
}

.popup__control-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup__btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.popup__back-icon {
  width: 32px;
  height: 32px;
}

.popup__back-icon-stroke {
  stroke: var(--neutral-active);
}

.popup__btn-text {
  text-align: center;
  font-family: "ALS Hauss";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 133%;
}

.popup__close-icon {
  width: 20px;
  height: 20px;
}

.popup__close-icon-stroke {
  stroke: var(--neutral-active);
}

.popup__close-icon-fill {
  fill: var(--neutral-active);
}

@media (max-width: 1150px) {
  .popup__container {
    padding: 26px;
  }
}
