.quote-element {
  width: calc(100% - 34px);
  position: relative;
  border-radius: 30px;
  background: var(--article-container-color);
  box-sizing: border-box;
  padding: 25px 72px;
  margin-top: 42px;
}

.quote-element__icon {
  width: 82px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: -51px;
  right: -66px;
}

.quote-element__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 900px) {
  .quote-element {
    width: 100%;
    padding: 25px 20px;
    margin-top: 68px;
  }

  .quote-element__icon {
    width: 62px;
    top: -58px;
    right: -4px;
  }

  .quote-element__text {
    font-size: 12px;
  }
}
