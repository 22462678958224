.pay-example__card {
    padding: 27px;
    border-radius: 16px;
    border: 1px solid var(--light-grey-border);
    background: var(--website-background);
    box-shadow: 2px 5px 9.7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 516px;
    box-sizing: border-box;
}

.pay-example__header {
    display: grid;
    grid-template-columns: 99px 312px 45px;
    height: 20px;
    gap: 7px;
}

.pay-example__header__line {
    border-radius: 10px;
    background: var(--footer-border);
}

.pay-example__header__line_orange {
    background: var(--pay-example-orange-line);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pay-example__card__gallery {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pay-example__card__img {
    width: 250px;
    object-fit: cover;
    height: 240px;
}

.pay-example__card__img_mini {
    width: 69px;
    height: 66px;
    object-fit: cover;
}

.pay-example__card__box-info {
    display: flex;
    gap: 17px;
    margin-top: 10px;
}

.pay-example__card__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 34px;
}

.pay-example__card__info__line-1 {
    width: 178px;
    height: 9px;
}

.pay-example__card__info__line-2 {
    width: 137px;
    height: 9px;
}

.pay-example__card__info__price {
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-top: 5px;
}

.pay-example__card__info__button-block {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 21px;
    width: 100%;
}

.pay-example__card__info__button {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    max-width: 70px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--neutral-active);
    background: var(--website-background);
    height: 20px;

    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.pay-example__card__info__button_black {
    background: var(--neutral-active);
    color: var(--website-background);
    font-weight: 400;
    max-width: 102px;
}

.pay-example__card__info_bottom {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 127px;
    margin-top: 34px;
}

.pay-example__card__info_bottom__line-1,
.pay-example__card__info_bottom__line-4 {
    max-width: 87px;
    border-radius: 10px;
    background: var(--footer-border);
    height: 3px;
}

.pay-example__card__info_bottom__line-2,
.pay-example__card__info_bottom__line-3 {
    width: 100%;
    border-radius: 10px;
    background: var(--footer-border);
    height: 3px;
}

.pay-example__text {
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-top: 21px;
    max-width: 400px;
}