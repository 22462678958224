.checkbox {
  width: 100%;
}

.checkbox__container {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 12px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid var(--partners-color-accent);
  background: var(--primary-text);
  cursor: pointer;
}

.checkbox__check-icon {
  width: 14px;
  height: 14px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox__pseudo-item_checked .checkbox__check-icon {
  opacity: 1;
}

.checkbox__check-icon-stroke {
  stroke: var(--partners-check-icon);
}

.checkbox__label {
  color: var(--primary-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142%;
}

.checkbox__label a {
  color: var(--primary-text);
  text-decoration-line: underline;
}
