@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey.eot');
    src: url('FSJoey.eot?#iefix') format('embedded-opentype'),
        url('FSJoey.woff2') format('woff2'),
        url('FSJoey.woff') format('woff'),
        url('FSJoey.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-Italic.eot');
    src: url('FSJoey-Italic.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-Italic.woff2') format('woff2'),
        url('FSJoey-Italic.woff') format('woff'),
        url('FSJoey-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-BoldItalic.eot');
    src: url('FSJoey-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-BoldItalic.woff2') format('woff2'),
        url('FSJoey-BoldItalic.woff') format('woff'),
        url('FSJoey-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-HeavyItalic.eot');
    src: url('FSJoey-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-HeavyItalic.woff2') format('woff2'),
        url('FSJoey-HeavyItalic.woff') format('woff'),
        url('FSJoey-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-Medium.eot');
    src: url('FSJoey-Medium.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-Medium.woff2') format('woff2'),
        url('FSJoey-Medium.woff') format('woff'),
        url('FSJoey-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-Light.eot');
    src: url('FSJoey-Light.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-Light.woff2') format('woff2'),
        url('FSJoey-Light.woff') format('woff'),
        url('FSJoey-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-Bold.eot');
    src: url('FSJoeyPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-Bold.woff2') format('woff2'),
        url('FSJoeyPro-Bold.woff') format('woff'),
        url('FSJoeyPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-Bold.eot');
    src: url('FSJoey-Bold.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-Bold.woff2') format('woff2'),
        url('FSJoey-Bold.woff') format('woff'),
        url('FSJoey-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoeyLight-LightItalic.eot');
    src: url('FSJoeyLight-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyLight-LightItalic.woff2') format('woff2'),
        url('FSJoeyLight-LightItalic.woff') format('woff'),
        url('FSJoeyLight-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-BoldItalic.eot');
    src: url('FSJoeyPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-BoldItalic.woff2') format('woff2'),
        url('FSJoeyPro-BoldItalic.woff') format('woff'),
        url('FSJoeyPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoeyMedium-MediumItalic.eot');
    src: url('FSJoeyMedium-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyMedium-MediumItalic.woff2') format('woff2'),
        url('FSJoeyMedium-MediumItalic.woff') format('woff'),
        url('FSJoeyMedium-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey';
    src: url('FSJoey-Heavy.eot');
    src: url('FSJoey-Heavy.eot?#iefix') format('embedded-opentype'),
        url('FSJoey-Heavy.woff2') format('woff2'),
        url('FSJoey-Heavy.woff') format('woff'),
        url('FSJoey-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-Light.eot');
    src: url('FSJoeyPro-Light.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-Light.woff2') format('woff2'),
        url('FSJoeyPro-Light.woff') format('woff'),
        url('FSJoeyPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-Medium.eot');
    src: url('FSJoeyPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-Medium.woff2') format('woff2'),
        url('FSJoeyPro-Medium.woff') format('woff'),
        url('FSJoeyPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-HeavyItalic.eot');
    src: url('FSJoeyPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-HeavyItalic.woff2') format('woff2'),
        url('FSJoeyPro-HeavyItalic.woff') format('woff'),
        url('FSJoeyPro-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-Heavy.eot');
    src: url('FSJoeyPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-Heavy.woff2') format('woff2'),
        url('FSJoeyPro-Heavy.woff') format('woff'),
        url('FSJoeyPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-Italic.eot');
    src: url('FSJoeyPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-Italic.woff2') format('woff2'),
        url('FSJoeyPro-Italic.woff') format('woff'),
        url('FSJoeyPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-LightItalic.eot');
    src: url('FSJoeyPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-LightItalic.woff2') format('woff2'),
        url('FSJoeyPro-LightItalic.woff') format('woff'),
        url('FSJoeyPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro.eot');
    src: url('FSJoeyPro.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro.woff2') format('woff2'),
        url('FSJoeyPro.woff') format('woff'),
        url('FSJoeyPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Joey Pro';
    src: url('FSJoeyPro-MediumItalic.eot');
    src: url('FSJoeyPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('FSJoeyPro-MediumItalic.woff2') format('woff2'),
        url('FSJoeyPro-MediumItalic.woff') format('woff'),
        url('FSJoeyPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

