.list-element {
  width: 100%;
  margin-top: 12px;
}

.list-element__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: disc outside;
  box-sizing: border-box;
  padding-left: 20px;
}

.list-element__list_type_ordered {
  list-style: decimal outside;
}

.list-element__item {
  color: var(--neutral-active);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 900px) {
  .list-element__list {
    padding-left: 16px;
  }

  .list-element__item {
    font-size: 12px;
  }
}
