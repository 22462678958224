@font-face {
    font-family: 'ALS Hauss';
    src: url('ALSHauss-Regular.eot');
    src: url('ALSHauss-Regular.eot?#iefix') format('embedded-opentype'),
        url('ALSHauss-Regular.woff2') format('woff2'),
        url('ALSHauss-Regular.woff') format('woff'),
        url('ALSHauss-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('ALSHauss-Medium.eot');
    src: url('ALSHauss-Medium.eot?#iefix') format('embedded-opentype'),
        url('ALSHauss-Medium.woff2') format('woff2'),
        url('ALSHauss-Medium.woff') format('woff'),
        url('ALSHauss-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

