.faq {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 80px 80px 70px;
}

.faq__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 550;
    line-height: 100%;
    /* 100% */
    letter-spacing: -1.12px;
    max-width: 768px;
}

.faq__subtitle {
    margin: 20px 0 0;
    text-align: center;
    max-width: 480px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.7;
}

.faq__items {
    margin: 24px 0 0;
    display: flex;
    flex-direction: column;
    max-width: 840px;
    width: 100%;
}

.faq__item {
    padding: 24px 32px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: max-content;
    column-gap: 24px;

    /* border-radius: 16px; */
    transition: all 0.2s ease-in-out;
    border: 2px solid transparent;
    border-top: 1px solid var(--neutral-separetor);
}

.faq__item:first-of-type {
    border-top: 2px solid transparent;
}

.faq__item_selected {
    border-radius: 16px;

    border: 2px solid var(--primary-active) !important;


}



.faq__item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.faq__item-arrow {
    width: 24px;
    height: 24px;
    transition: all 0.4s ease-in-out;

}

.faq__item_selected .faq__item-arrow {
    transform: rotate(180deg);
}

.faq__item-arrow-stroke {
    stroke: var(--neutral-active);
    transition: all 0.2s ease-in-out;

}

.faq__item_selected .faq__item-arrow-stroke {
    stroke: var(--primary-active);
}

.faq__item-info-question {
    font-size: 18px;
    font-style: normal;
    font-weight: 750;
    line-height: 28px;
    text-align: left;
}

.faq__item-info-answer {
    font-family: "ALS Hauss";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    opacity: 0.7;
    margin: 8px 0 0;
    white-space: pre-line;
}

.faq__item-info-answer-link {
    text-decoration: underline;
}

@media (max-width: 880px) {
    .faq {
        padding: 50px 24px 25px;
    }

    .faq__title {
       font-size: 36px;
        font-style: normal;
        font-weight: 750;
        line-height: 36px;
    }

    .faq__subtitle{
        font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
margin: 12px 0 0;
    }
    .faq__items{
        margin: 28px 0 0;
    }
    .faq__item{
        padding: 20px;
    }

    .faq__item-info-question{
        font-size: 16px;
font-style: normal;
font-weight: 550;
line-height: 22px;
    }

    .faq__item-info-answer{
        font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin: 8px 0 0;
    }
}