.subscribe {
  width: 100%;
  max-width: 810px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribe__title {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 133%;
}

.subscribe__form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 110px;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
}

.subscribe__input {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--neutral-active);
  font-family: "ALS Hauss";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 240px;
  border: 3px solid var(--neutral-active);
  background: transparent;
  overflow: auto;
  outline: none;
  box-sizing: border-box;
  padding: 0 46px;
  transition: all 0.2s ease-in-out;
}

.subscribe__input::placeholder {
  color: var(--banner-placeholder);
}

.subscribe__input_focus {
  border-color: var(--primary-active);
}

.subscribe__submit-btn {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "ALS Hauss";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--primary-text);
  background-color: var(--primary-active);
  border-radius: 240px;
  box-sizing: border-box;
  padding: 0 74px;
  transition: all 0.2s ease-in-out;
}

.subscribe__submit-btn:hover {
  background: var(--primary-extra-button);
}

.subscribe__submit-btn_disabled {
  background: var(--primary-disabled);
  pointer-events: none;
  user-select: none;
}

@media (max-width: 900px) {
  .subscribe__form {
    grid-template-rows: 56px;
    gap: 16px;
    border-radius: 150px;
    border: 2px solid var(--neutral-active);
    box-sizing: border-box;
    padding: 4px 4px 4px 26px;
    margin-top: 46px;
    transition: all 0.2s ease-in-out;
  }

  .subscribe__form_focus {
    border-color: var(--primary-active);
  }

  .subscribe__input {
    font-size: 15px;
    border-radius: 0;
    border: none;
    padding: 0;
  }

  .subscribe__input_focus {
    border-color: unset;
  }

  .subscribe__submit-btn {
    font-size: 15px;
    border-radius: 150px;
    padding: 0 46px;
  }

  .subscribe__submit-btn:hover {
    background: var(--primary-active);
  }
}
