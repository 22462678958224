.text-element {
  width: 100%;
  container-type: inline-size;
}

.text-element__title {
  font-size: 36px;
  font-style: normal;
  font-weight: 750;
  line-height: 120%;
  padding-top: 80px;
}

.text-element__subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 750;
  line-height: 116.5%;
  padding-top: 40px;
}

.text-element__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-top: 12px;
}

@media (max-width: 900px) {
  .text-element__title {
    font-size: 20px;
    padding-top: 40px;
  }

  .text-element__subtitle {
    font-size: 16px;
    padding-top: 20px;
  }

  .text-element__text {
    font-size: 12px;
  }
}
