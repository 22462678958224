.head-banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 36px 0;
    box-sizing: border-box;
    align-items: center;
}

.head-banner__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1920px;
    box-sizing: border-box;
    padding: 0 80px;
    align-items: center;
}

.head-banner__title {
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 750;
    line-height: 88px;
    max-width: 1000px;
    letter-spacing: 2px;
}

.head-banner__input-box {
    /* display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content; */
    display: flex;
    max-height: 110px;
    justify-content: space-between;

    column-gap: 24px;
    padding: 11px 14px 11px 47px;
    box-sizing: border-box;
    width: 100%;
    max-width: fit-content;
    border-radius: 71px;
    border: 4px solid var(--neutral-active);
    margin: 60px 0 0;
    align-items: center;
    transition: all 0.2s linear;
}

.head-banner__input-box_inactive {
    border: 4px solid #4200FF;
    transition: all 0.2s linear;
}

.head-banner__input-box_valid {
    border: 4px solid var(--partners-second-card-border);
    transition: all 0.2s linear;
}

.head-banner__input-box_not-valid {
    border: 4px solid #FF0000 !important;
    transition: all 0.2s linear;
}

.head-banner__input {
    color: var(--neutral-active);
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.558px;
    background: transparent;
    padding: 0;
    z-index: 1;
    overflow: auto;
    outline: none;
    border: none;
    width: 50%;
}

.head-banner__input::placeholder {
    color: var(--banner-placeholder);
}

.head-banner__button {
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.558px;
    background-color: var(--primary-active);
    color: var(--primary-text);
    border-radius: 44px;
    box-sizing: border-box;
    padding: 0px 40px;
    width: fit-content;
    min-height: 80px;
    max-height: 80px;
    transition: all 0.2s linear;
    text-wrap: nowrap;
}

.head-banner__button:hover {
    background: var(--primary-extra-button);
    transition: all 3ms ease-in-out;
}

.head-banner__button:active {
    background-color: var(--primary-active);
    transition: all 3ms ease-in-out;
}

.head-banner__button_inactive {
    background: var(--primary-disabled);
    transition: all 0.2s linear;
    pointer-events: none;
    user-select: none;
}

.head-banner__button_diasbled {
    pointer-events: none;
    user-select: none;
}

.head-banner__slides-swiper {
    width: 100%;
    position: relative;
}

.head-banner__slides-swiper_mobile {
    display: none;
}

.head-banner__gradient {
    width: 35%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(95deg, var(--website-background) 3.93%, rgba(255, 255, 255, 0.00) 95.71%);
    z-index: 2;
}

.head-banner__gradient_type_right {
    left: unset;
    right: 0;
    transform: scaleX(-1);
}

.head-banner__slides {
    margin: 51px 0 0;
    overflow: hidden;
    width: 100%;
}

.head-banner__slide {
    background-position: center;
    background-size: cover;
    width: 233px !important;
    height: 469px !important;
}

.head-banner__slide-img {
    display: block;
    width: 100%;
    max-height: 469px;
}


.swiper-pagination-bullet-active {
    background-color: var(--primary-active) !important;

    /* width: 19px !important;
    height: 19px !important; */
}

.head-banner__button_mobile {
    display: none;
}

.head-banner__swiper-controls {
    margin: 30px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.head-banner__swiper-control {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.head-banner__swiper-control_back {
    background: var(--primary-200, var(--swiper-state-icon));
    transition: all 0.2s linear;
}

.head-banner__swiper-control_back:hover, .head-banner__swiper-control_next:hover {
    background: var(--swiper-state-hover);
    transition: all 0.2s linear;
}

.head-banner__swiper-control_back:active, .head-banner__swiper-control_next:active {
    background: var(--swiper-state-active);
    transition: all 0.2s linear;
}

.head-banner__swiper-control_next {
    background: var(--primary-200, var(--swiper-state-icon));
    transition: all 0.2s linear;
}

.head-banner__slides .swiper-pagination {
    display: none !important;
}

.head-banner__swiper-control-icon {
    width: 24px;
    height: 24px;
    transition: all 0.2s linear;
}

.head-banner__swiper-control_next .head-banner__swiper-control-icon {
    transform: rotate(180deg);
}

.head-banner__swiper-control-icon, .head-banner__swiper-control-icon-stroke {
    stroke: black;
    transition: all 0.2s linear;
}

.head-banner__swiper-control:hover .head-banner__swiper-control-icon, .head-banner__swiper-control:hover .head-banner__swiper-control-icon-stroke {
    stroke: white;
    transition: all 0.2s linear;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(1) !important;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(1) !important;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next-next {
    transform: scale(1) !important;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(1) !important;
}

@media (max-width: 780px) {
    .head-banner__title {
        font-size: 56px;
        line-height: 120%;
        /* max-width: 538px; */
    }

    .head-banner__main {
        padding: 0 24px;
    }
}

@media (max-width: 600px) {
    .head-banner__button_mobile {
        display: flex;

        text-align: center;
        font-family: "ALS Hauss";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 31.558px;
        background-color: var(--primary-active);
        color: var(--primary-text);
        border-radius: 44px;
        box-sizing: border-box;
        /* padding: 24px 58px; */
        transition: all 0.2s linear;
        min-height: 64px;
        max-height: 64px;
    }

    .head-banner__button_inactive {
        background: rgba(53, 198, 80, 0.55);
        transition: all 0.2s linear;
    }

    .head-banner__button_pc {
        display: none;
    }

    .head-banner__input-box {
        max-width: unset;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1913.901px;
        border: 3.315px solid var(--neutral-active);
        padding: 0 22px;
        margin: 22px 0 0;
        height: 64px;
    }

    .head-banner__input-box_inactive {
        border: 3.315px solid #4200FF;
        transition: all 0.2s linear;
    }

    .head-banner__input-box_valid {
        border: 3.315px solid var(--partners-second-card-border);
        transition: all 0.2s linear;
    }

    .head-banner__input-box_not-valid {
        border: 3.315px solid #FF0000 !important;
        transition: all 0.2s linear;
    }

    .head-banner__input {
        font-size: 17.682px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.25px;
        width: 100%;
    }

    .head-banner__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        /* max-width: 274px; */
    }

    .head-banner__button {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 19px 0 0;
        padding: 16px 0;
        font-size: 17.682px;
        font-style: normal;
        font-weight: 500;
        height: 64px;
    }

    .head-banner__slides-swiper {
        display: none;
    }

    .head-banner__slides-swiper_mobile {
        display: block;
    }

    .head-banner__slides {
        margin: 30px 0 0;
        padding-bottom: 50px !important;
    }

    .head-banner__slide {
        width: 189.013px !important;
        height: 383.39px !important;
    }

    .head-banner__slides .swiper-pagination {
        display: flex !important;
        align-items: center;
        justify-self: center;
        align-self: center;
        justify-content: center;
        gap: 20px;
    }

    .head-banner__slides .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
    }

    .head-banner__slides .swiper-pagination-bullet-active {
        width: 20px;
        height: 20px;
    }

    .head-banner__swiper-controls {
        display: none;
    }
}