.logistics {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 80px 0 50px;
}

.logistics__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 57.5px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.logistics__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 26px;
    text-align: center;
    max-width: 575px;
    margin: 12px 0 0;
    position: relative;
    z-index: 1;
}

.logistics__video {
    width: 100%;
    object-fit: cover;
    margin: -200px 0 0;
    position: relative;
    z-index: 0;
}

@media (max-width: 1000px) {
    .logistics {
        padding: 50px 80px;
    }

    .logistics__title {
        font-size: 44px;
        font-style: normal;
        font-weight: 750;
        line-height: 120%;
        max-width: 586px;
    }
}

@media (max-width: 880px) {
    .logistics {
        padding: 51px 0 33px;
        overflow: hidden;
    }


    .logistics__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;

    }

    .logistics__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 550;
        line-height: 26px;
        margin: 10px 0 0;
    }

    .logistics__video {
        width: 150%;
        margin: -40px 0 0;
    }
}