.partners-target {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 100px 0 128px;
}

.partners-target__bg {
  width: 114.64%;
  min-width: 1073px;
  height: 125.42%;
  min-height: 521px;
  object-fit: fill;
  transform: rotate(169.044deg);
  user-select: none;
  pointer-events: none;
  position: absolute;
  bottom: -24%;
  z-index: 0;
}

.partners-target__title {
  position: relative;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 121%;
  padding: 0 32px;
  z-index: 1;
}

.partners-target__swiper {
  width: 100%;
  margin-top: 88px;
}

.partners-target__slide {
  width: fit-content !important;
}

.partners-target__slide-container {
  width: 448px;
  height: 296px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  border-radius: 18px;
  border: 1px solid var(--partners-target-card-color);
  background: var(--website-background);
}

.partners-target__slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.partners-target__title-box {
  width: 100%;
  height: 226px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  bottom: -42px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--partners-target-card-color) 100%);
  box-sizing: border-box;
  padding: 0 35px 58px;
}

.partners-target__slide-title {
  color: var(--primary-text);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 200;
  line-height: 145%;

}

@media (max-width: 780px) {
  .partners-target {
    padding: 118px 0;
  }

  .partners-target__bg {
    height: 521px;
    transform: rotate(180deg);
  }

  .partners-target__title {
    font-size: 28px;
    line-height: 114%;
    padding: 0 20px;
  }

  .partners-target__swiper {
    margin-top: 35px;
  }

  .partners-target__slide-container {
    width: 277px;
    height: 183px;
    border-radius: 12px;
  }

  .partners-target__title-box {
    height: 140px;
    bottom: -25px;
    padding: 0 16px 35px;
  }

  .partners-target__slide-title {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .partners-target__bg {
    left: -52.3%;
  }
}
