.suitable {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--website-secondary-background);
    padding: 100px 0 71px;
    overflow: hidden;
    position: relative;
}

.suitable__content {
    box-sizing: border-box;
    width: 100%;

    max-width: 1920px;
    display: grid;
    grid-template-columns: 560px 1fr;
    grid-template-rows: max-content;
    column-gap: 110px;

    padding: 0 160px;
    align-items: center;
}

.suitable__info {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.suitable__info-title {
    font-size: 36px;
    font-style: normal;
    font-weight: 750;
    line-height: 116%;
}

.suitable__info-btns {
    display: flex;
    flex-direction: row;
    margin: 35px 0 0;
}

.suitable__info-btn {
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding: 40px 46px;
    box-sizing: border-box;
    border-radius: 240px;
    border: 3px solid var(--neutral-active);
    transition: all 0.2s ease-in-out;
}

.suitable__info-btn_selected {
    border-color: var(--primary-active);
    color: var(--primary-active);
}

.suitable__info-main-percent {
    font-size: 89.149px;
    font-style: normal;
    font-weight: 550;
    line-height: 115%;
    margin: 161px 0 0;
    text-align: left;
    color: var(--primary-active);
}

.suitable__info-main-percent-numbers {
    width: 130px;
    font-size: 89.149px;
    font-style: normal;
    font-weight: 550;
    line-height: 115%;
    color: var(--primary-active);

}

.suitable__info-main-description {
    font-size: 17px;
    font-style: normal;
    font-weight: 550;
    margin: 6px 0 0;
    max-width: 395px;
}

.suitable__info-why-title {
    font-size: 25.6px;
    font-style: normal;
    font-weight: 550;
    line-height: 130%;
    margin: 52px 0 0;
}

.suitable__info-why-items {
    margin: 22px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.suitable__info-why-item {
    width: 100%;
    margin-top: 17px;
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}


.suitable__info-why-item:first-of-type {
    margin-top: 0;
}

.suitable__info-why-item-icon {
    width: 45px;
    height: 45px;
}

.suitable__info-why-item-text {
    font-size: 19.2px;
    font-style: normal;
    font-weight: 550;
    line-height: 130%;
}

.suitable__chart {
    width: 100%;
    height: 100%;
}


.suitable__chart_pc {}

.suitable__chart_mobile {
    display: none;
}

@media (max-width: 1800px) {
    .suitable__content {
        padding: 0 80px;
    }
}

@media (max-width: 1400px) {
    .suitable__content {
        padding: 0 0 0 80px;
        column-gap: 0;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1280px) {
    .suitable__content {
        padding: 0 80px;
        grid-template-columns: 1fr;
    }

    .suitable__chart_pc {
        display: none;
    }

    .suitable__chart_mobile {
        display: inline-block;
        height: 700px;
    }

    .suitable__info-main-percent {
        margin: 20px 0 0;
    }
}

@media (max-width: 880px) {
    .suitable {
        padding: 31px 0;
    }

    .suitable__content {
        padding: 0 17px;
    }

    .suitable__info-title {
        padding: 0 calc(32px - 17px);
        font-size: 26px;
        font-style: normal;
        font-weight: 750;
        line-height: 110%;
        max-width: 276px;
        box-sizing: border-box;
    }

    .suitable__info-btns {
        margin: 19px 0 0;
        padding: 0 calc(32px - 17px);
        box-sizing: border-box;
    }

    .suitable__info-btn {
        padding: 24px 28px;
        font-size: 14.276px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        border-width: 2px;
    }

    .suitable__info-main-percent {
        font-size: 62.771px;
        font-style: normal;
        font-weight: 550;
        line-height: 115%;
        padding: 0 calc(20px - 17px);
        box-sizing: border-box;
    }

    .suitable__info-main-percent-numbers{
        font-size: 62.771px;
        font-style: normal;
        font-weight: 550;
        line-height: 115%;
    }

    .suitable__info-main-description {
        margin: 4px 0 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 550;
        line-height: 130%;
        max-width: 278px;
        padding: 0 calc(20px - 17px);
        box-sizing: border-box;
    }

    .suitable__info-why-title {
        margin: 30px 0 0;
        padding: 0 calc(30px - 17px);
        box-sizing: border-box;
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        line-height: 130%;
    }

    .suitable__info-why-items {
        margin: 14px 0 0;
    }

    .suitable__info-why-item {
        grid-template-columns: 30px 1fr;
        column-gap: 6px;
        padding: 0 calc(20px - 17px);
    }

    .suitable__info-why-item-icon {
        width: 30px;
        height: 30px;
    }

    .suitable__info-why-item-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 550;
        line-height: 130%;
    }

}


@media (max-width: 750px) {
    .suitable__chart_mobile {
        height: 650px;
    }
}


@media (max-width: 700px) {
    .suitable__chart_mobile {
        height: 600px;
    }
}

@media (max-width: 650px) {
    .suitable__chart_mobile {
        height: 550px;
    }
}


@media (max-width: 600px) {
    .suitable__chart_mobile {
        height: 500px;
    }
}

@media (max-width: 550px) {
    .suitable__chart_mobile {
        height: 450px;
    }
}

@media (max-width: 500px) {
    .suitable__chart_mobile {
        height: 400px;
    }
}


@media (max-width: 450px) {
    .suitable__chart_mobile {
        height: 350px;
    }
}


@media (max-width: 400px) {
    .suitable__chart_mobile {
        height: 300px;
    }
}