.say-about-us {
    background-color: var(--website-secondary-background);
    padding: 80px 80px 92px 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.say-about-us__title {
    text-align: center;
    font-size: 55px;
    font-style: normal;
    font-weight: 750;
    line-height: 100%;
    width: 100%;
    max-width: 960px;
}

.say-about-us__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    margin-top: 16px;
}

.say-about-us__box-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 497px 565.834px;
    justify-content: center;
    grid-template-areas:
        'big big';
    margin-top: 60px;
    row-gap: 30px;
    column-gap: 25px;
}

.say-about-us__card {
    border-radius: 60px;
    border: 3px solid var(--website-third-background);
    background: var(--website-background);
}

.say-about-us__card_big {
    grid-area: big;
    width: 100%;
    padding: 0 43px 0 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}

.say-about-us__card__img {
    margin: auto 0;
    max-height: 399px;
    max-width: 610px;
}

.say-about-us__card__text-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: left;
    justify-content: center;
}

.say-about-us__card__title {
    color: var(--primary-text) !important;
    font-size: 33px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;

    border-radius: 13px 14px 0px 0px;
    background: var(--primary-text-background);
    max-width: 287px;
    padding: 23px;
    /* box-sizing: border-box; */
    max-height: 220px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.say-about-us__card__author {
    font-size: 21px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;
}

.say-about-us__card_small {
    padding: 37px 43px 0 42px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.say-about-us__card_small .say-about-us__card__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;
    max-width: 470px;
    max-height: 100px;
}

.say-about-us__card_small .say-about-us__card__text-box {
    max-width: 470px;
}

.say-about-us__card_small .say-about-us__card__img {
    position: absolute;
    left: -0px;
    max-width: 662px;
    top: 115px;
    max-height: 442px;
    height: 100%;
    width: 100%;
}

@media (max-width:1195px) {
    .say-about-us {
        padding: 40px 16px 60px 16px;
    }

    .say-about-us__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .say-about-us__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .say-about-us__box-card {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 365px);
        grid-template-areas: 'big';
        row-gap: 18.85px;
    }

    .say-about-us__card_big {
        padding: 23.53px 27px 0 27px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .say-about-us__card_small {
        padding: 23.53px 27px 0 27px;
        display: flex;
        flex-direction: column;
    }

    .say-about-us__card_big .say-about-us__card__text-box {
        max-width: 100%;
    }

    .say-about-us__card_small .say-about-us__card__text-box {
        max-width: 100%;
    }

    .say-about-us__card_big .say-about-us__card__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 750;
        line-height: 116.5%;
        max-width: 100%;
        max-height: 77px;
        padding: 14.8px;
    }

    .say-about-us__card_small .say-about-us__card__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 750;
        line-height: 116.5%;
        max-width: 100%;
        max-height: 77px;
        padding: 14.8px;
    }

    .say-about-us__card__text-box {
        gap: 7.74px;
    }

    .say-about-us__card__author {
        font-size: 13.546px;
        font-style: normal;
        font-weight: 750;
        line-height: 116.5%;
    }

    .say-about-us__card_small .say-about-us__card__img {
        max-width: 300px;
        max-height: 290px;
        top: unset;
        left: unset;
        position: relative;
    }

    .say-about-us__card__img {
        max-width: 300px;
        align-self: center;
    }
}