.success-in-numbers {
    padding: 146px 80px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--website-secondary-background);
}

.success-in-numbers__title {
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    /* 74.324% */
}

.success-in-numbers__cards-box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 268px 268px;
    row-gap: 76px;
    column-gap: 22.15px;
    grid-template-areas:
        'card1 card1 card1 card2 card2 card2'
        'card3 card3 card4 card4 card5 card5'
    ;
    margin-top: 120px;
    max-width: 1217px;
    width: 100%;
}

.success-in-numbers__card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 27px;
    gap: 31px;
    align-items: center;
    justify-items: center
}

.success-in-numbers__card__info_visual {
    border-radius: 22.147px;
    background: var(--website-third-background);
    display: flex;
    align-items: center;
    gap: 36px;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.success-in-numbers__card__info_visual__number {
    color: var(--primary-active);
    font-size: 81.03px;
    font-style: normal;
    font-weight: 750;
    line-height: 115.5%;
}

.success-in-numbers__card__info__visual-number-and-text {
    display: flex;
    align-items: flex-start;
}

.success-in-numbers__card__info__visual-number-and-text_card1 {
    align-items: center;
    gap: 0px;
    margin-left: 11px;
}

.success-in-numbers__card__info__visual-number-and-text_card2,
.success-in-numbers__card__info__visual-number-and-text_card5 {
    flex-direction: column;
    margin-left: 3px;
}

.success-in-numbers__card__info__visual-number-and-text_card4 {
    flex-direction: column-reverse;
    margin-left: -3px;
}

.success-in-numbers__card__info__visual-number-and-text_card3 {
    margin-left: -3px;
}

.success-in-numbers__card__info_visual__text {
    color: var(--neutral-active);
    text-transform: uppercase;
    font-style: normal;
    font-weight: 750;
    line-height: 115.5%;
}

.success-in-numbers__card__info_visual__text_card1 {
    font-size: 67.881px;
}

.success-in-numbers__card__info_visual__text_card2 {
    font-size: 36.939px;
}

.success-in-numbers__card__info_visual__text_card4 {
    font-size: 44.7px;
}

.success-in-numbers__card__info_visual__text_card5 {
    font-size: 30.686px;
}

.success-in-numbers__card__info {
    font-size: 23.255px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;
    align-self: flex-start;
}

.visual-count-number {
    display: flex;

}

@media (max-width:1200px) {
    .success-in-numbers__card__info_visual__number {
        font-size: 47.301px;
        font-style: normal;
        font-weight: 750;
        line-height: 115.5%;
        /* 54.633px */
    }


    .success-in-numbers__card__info_visual__text_card1 {
        font-size: 33.917px;
    }

    .success-in-numbers__card__info_visual__text_card2 {
        font-size: 15.314px;
    }

    .success-in-numbers__card__info_visual__text_card4 {
        font-size: 15.223px;
    }

    .success-in-numbers__card__info_visual__text_card5 {
        font-size: 22.206px;
    }

    .success-in-numbers__card__info {
        font-size: 16.888px;
        font-style: normal;
        font-weight: 750;
        line-height: 116.5%;
        /* 19.674px */
        align-self: center;
        text-align: center;
    }

    .success-in-numbers__cards-box {
        margin-top: 31px;
    }
}

@media (max-width:960px) {

    .success-in-numbers {
        padding: 32px 0px 72px 0px;
        background-color: var(--website-background);
    }

    .success-in-numbers__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 114.286% */
    }

    .success-in-numbers__cards-box {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 140px);
        row-gap: 20px;
        column-gap: 20px;
        grid-template-areas:
            '. card1 card1 .'
            'card2 card2 card3 card3'
            'card4 card4 card5 card5'
        ;
        margin-top: 120px;
        max-width: 1217px;
        width: 100%;
        margin-top: 30px;
        padding: 0 19px;
        box-sizing: border-box;
    }

    .success-in-numbers__card__info_visual {
        justify-content: space-around;
        gap: 0;
    }

    .success-in-numbers__card__info_visual__number {
        font-size: 32px;
    }

    .success-in-numbers__card__info_visual__text_card5 {
        font-size: 15px;
    }

    .success-in-numbers__card__info {
        font-size: 14px;
    }
}

@media (max-width:400px) {
    .success-in-numbers__card__info {
        font-size: 12px;
    }
    .success-in-numbers__card__info_visual{
        flex-direction: column;
     min-height: 140px;
    }
    .success-in-numbers__card{
        gap: 0px;
        grid-template-columns: 1fr;
        grid-template-rows: 140px 26px;
    }
    .success-in-numbers__card__info{
        margin-top: 16px;
    }
    .success-in-numbers__cards-box{
        row-gap: 40px;
        grid-template-rows: repeat(3, 1fr);
    }
}