.donut-chart {
    width: 100%;
    height: 100%;
    position: relative;
}

.donut-chart__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.donut-chart__inner-percent {
    text-align: center;
    font-size: 100px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
}

.donut-chart__inner-text {
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 130%;
    max-width: 236px;
}

.donut-chart .recharts-surface {
    user-select: none;
}

.donut-chart .recharts-surface * {
    outline: none;
}

.donut-chart__gradient {
    transition: all 0.5s ease-out;
    /* Adjust time and easing function as needed */
}

@media (max-width: 600px) {
    .donut-chart__inner-percent {
        font-size: 56.863px;
        font-style: normal;
        font-weight: 900;
        line-height: 57.934px;
    }

    .donut-chart__inner-text {
        font-size: 10.235px;
        font-style: normal;
        font-weight: 550;
        line-height: 13.647px;
        max-width: 134px;
    }
}