.bennefits {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 100px 80px 108px;
}

.bennefits__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 110%;
    max-width: 932px;
}

.bennefits__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0 0;
    max-width: 932px;
}

.bennefits__items {
    margin: 49px 0 0;
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-template-rows: max-content;
    column-gap: 20px;
    row-gap: 24px;
}

.bennefits__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;

}

.bennefits__item-img {
    width: 100%;
    aspect-ratio: 353 / 190;
    border-radius: 20px;
}

.bennefits__item-text {
    font-size: 21px;
    font-style: normal;
    font-weight: 750;
    line-height: 116.5%;
}

.bennefits__btn {
    max-width: 518px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--neutral-active);
    color: var(--website-background);
    padding: 28px 0;
    border-radius: 1731.843px;
    margin: 60px 0 0;

    font-family: "ALS Hauss";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

@media (max-width: 1100px) {
    .bennefits__title {
        font-size: 46px;
    }
}

@media (max-width: 880px) {
    .bennefits {
        padding: 50px 15px 63px;
    }

    .bennefits__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 28px;
        max-width: 294px;
    }

    .bennefits__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 12px 0 0;
        max-width: 252px;
    }

    .bennefits__items {
        grid-template-columns: repeat(2, minMax(0, 1fr));
        margin: 32px 0 0;
        column-gap: 8px;
        row-gap: 30px;
    }

    .bennefits__item {
        gap: 14px;
    }

    .bennefits__item-img {
        border-radius: 8px;
    }

    .bennefits__item-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 750;
        line-height: 116.5%;
    }
}

@media (max-width: 660px) {
    .bennefits__btn {
        width: 100%;
        max-width: unset;
        padding: 23px 0;
        margin: 50px 0 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }
}