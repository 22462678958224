.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.steps__smile-box {
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  will-change: transform;
}

.steps__smile {
  width: 100%;
  object-fit: contain;
  position: relative;
  user-select: none;
  pointer-events: none;
  z-index: 1;
}

.steps__smile-gradient-box {
  width: 100%;
  aspect-ratio: 636/67;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  will-change: height;
}

.steps__smile-gradient-box_type_top {
  transform: translateY(-50%);
}

.steps__smile-gradient-box_type_bottom {
  transform: translateY(50%);
}

.steps__smile-gradient {
  width: 100%;
  object-fit: fill;
  user-select: none;
  pointer-events: none;
}

.steps__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  will-change: opacity;
  box-sizing: border-box;
  padding: 4px 0;
}

.steps__current-step {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  background: var(--steps-color-accent);
  border-radius: 4px 4px 0 0;
  padding: 4px 8px;
  z-index: 2;
}

.steps__swiper-control {
  width: 18px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacity;
}

.steps__swiper-control_back {
  transform: rotate(90deg);
}

.steps__swiper-control_next {
  transform: rotate(-90deg);
}

.steps__swiper-control-icon {
  width: 100%;
  height: 100%;
}

.steps__swiper-control-icon-fill {
  fill: var(--primary-text);
}

.steps__current-text {
  color: var(--primary-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.steps__swiper {
  width: 100%;
  height: 610px;
  position: relative;
  z-index: 1;
}

.steps__slide-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  will-change: transform, opacity;
}

.steps__slide-title {
  max-width: 794px;
  color: var(--neutral-active);
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%;
  padding: 74px 32px 25px;
}

.steps__template-list {
  width: 100%;
  display: flex;
  align-items: center;
  will-change: transform;
}

.steps__template-item {
  display: flex;
}

.steps__slide-content {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.steps__boots-list {
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  bottom: 74px;
  left: 65px;
}

.steps__boots-item {
  display: flex;
  will-change: transform, opacity;
}

.steps__boot-img {
  width: 78px;
  height: 54px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.steps__slide-img {
  width: 186px;
  height: 128px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 20px;
  right: -15px;
}

.steps__slide-list {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.steps__slide-item {
  display: flex;
  position: absolute;
  will-change: transform, opacity;
}

.steps__slide-item_type_facebook {
  top: 75px;
  left: -20px;
}

.steps__slide-item_type_instagram {
  top: 10px;
  right: -87px;
}

.steps__slide-item_type_google {
  top: 85px;
  right: -122px;
}

.steps__slide-item_type_stat {
  width: 120px;
  height: 120px;
  top: 0;
  left: -125px;
}

.steps__slide-item_type_big-dollar {
  width: 85px;
  height: 85px;
  top: 81px;
  right: -110px;
}

.steps__slide-item_type_medium-dollar {
  width: 60px;
  height: 60px;
  top: 180px;
  left: -134px;
}

.steps__slide-item_type_small-dollar {
  width: 39px;
  height: 39px;
  bottom: -48px;
  right: 35px;
}

.steps__media-img {
  width: 67px;
  height: 67px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.steps__analyse-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.steps__template-icon {
  width: 461px;
  height: 377px;
}

.steps__template-card-icon {
  width: 233px;
  height: 256px;
}

.steps__template-analyse-icon {
  width: 298px;
  height: 325px;
}

.steps__template-bg {
  fill: var(--steps-template-bg);
}

.steps__template-fill {
  fill: var(--steps-template-lines);
}

.steps__template-box-bg {
  fill: var(--steps-template-box-bg);
}

.steps__template-light-fill {
  fill: var(--steps-template-light-lines);
}

@media (max-width: 850px) {
  .steps {
    padding: 16px 0;
  }

  .steps__smile-box {
    max-width: 480px;
    padding: 0 32px;
  }

  .steps__smile {
    width: 100%;
    object-fit: contain;
    position: relative;
    user-select: none;
    pointer-events: none;
    z-index: 1;
  }

  .steps__current-step {
    gap: 4px;
    top: 12px;
    padding: 3px 6px;
  }

  .steps__swiper-control {
    width: 12px;
    height: 6px;
  }

  .steps__current-text {
    font-size: 12px;
    font-weight: 500;
  }

  .steps__swiper {
    height: 320px;
  }

  .steps__slide-container {
    padding: 0 16px;
  }

  .steps__slide-title {
    max-width: 360px;
    font-size: 20px;
    font-weight: 750;
    line-height: 130%;
    padding: 50px 32px 0;
  }

  .steps__boots-list {
    gap: 8px;
    bottom: 37px;
    left: 33px;
  }

  .steps__boot-img {
    width: 39px;
    height: 27px;
  }

  .steps__slide-img {
    width: 94px;
    height: 65px;
    top: 10px;
    right: -8px;
  }

  .steps__slide-item_type_facebook {
    top: 38px;
    left: -10px;
  }

  .steps__slide-item_type_instagram {
    top: 5px;
    right: -44px;
  }

  .steps__slide-item_type_google {
    top: 43px;
    right: -62px;
  }

  .steps__slide-item_type_stat {
    width: 61px;
    height: 61px;
    top: 0;
    left: -63px;
  }

  .steps__slide-item_type_big-dollar {
    width: 43px;
    height: 43px;
    top: 41px;
    right: -55px;
  }

  .steps__slide-item_type_medium-dollar {
    width: 30px;
    height: 30px;
    top: 91px;
    left: -68px;
  }

  .steps__slide-item_type_small-dollar {
    width: 20px;
    height: 20px;
    bottom: -24px;
    right: 18px;
  }

  .steps__media-img {
    width: 34px;
    height: 34px;
  }

  .steps__template-icon {
    width: 233px;
    height: 190px;
  }

  .steps__template-card-icon {
    width: 118px;
    height: 129px;
  }

  .steps__template-analyse-icon {
    width: 150px;
    height: 164px;
  }
}
