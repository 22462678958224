.popup-join {
    position: fixed;
    z-index: 100000;
    top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: calc(100vh + 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -60px;

    opacity: 0;
}

.popup-join_opened {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

input[type="file"] {
    cursor: pointer;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

.popup-join__form__form-title {
    color: var(--partners-target-card-color);
}

.popup-join__form {
    max-width: 900px;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    backdrop-filter: blur(42.75px);

    padding: 35px 0px;
    box-sizing: border-box;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}

.popup-join__close-box {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-join__close {}

.popup-join__form__container {
    max-width: 526px;
    width: 100%;
}

.popup-join__form__inputs-block {
    margin-top: 32px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.popup-join .custom-input__input {
    border-radius: 34px;
    background: rgba(255, 255, 255, 0.25);
    color: var(--partners-target-card-color);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.popup-join .custom-input__input::placeholder {
    color: var(--partners-target-card-color);
    opacity: 0.5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: "Inter", serif;
    /* 171.429% */
}

.popup-join__form__form-title {
    color: var(--partners-target-card-color);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    /* 128.571% */
}

.popup-join .custom-input__label {
    color: var(--partners-target-card-color);
    font-family: "Inter", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}

.popup-join__form__input-file {
    border-radius: 1731.843px;
    border: 1px solid var(--partners-color-accent);
    color: var(--partners-color-accent);
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.039px;
    /* 116.882% */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    position: relative;
    gap: 7px;
    margin-top: 40px;
}

.popup-join__form__input-file__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 1732px;
}

.popup-join input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    font-family: 'Gorizont', sans-serif;
    -webkit-box-shadow: 0 0 0 500px var(--website-secondary-background) inset !important;
    /* можно изменить на любой вариант цвета */
    -webkit-text-fill-color: var(--neutral-active) !important;
    background-color: var(--website-secondary-background) !important;
    background-clip: content-box !important;
    position: relative;
    z-index: 0;
}

.popup-join__form__submit-btn {
    border-radius: 1731.843px;
    background: var(--partners-color-accent);
    color: var(--White, #FFF);
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.039px;
    /* 116.882% */
    width: 100%;
    padding: 20px 0;
    margin-top: 15px;
}

.popup-join__form__submit-btn_submit {
    transition: 0.5s background-color ease-in-out;
    background-color: var(--footer-button-second);
}