.content-nav {
  width: 100%;
  border-bottom: 1px solid var(--neutral-active);
  box-sizing: border-box;
  padding-bottom: 35px;
  margin: 35px 0 24px;
}

.content-nav__title-box {
  display: flex;
  align-items: center;
  gap: 12px;
}

.content-nav__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: 116.5%;
}

.content-nav__toggle-btn {
  width: 28px;
  height: 28px;
  display: flex;
}

.content-nav__arrow-icon {
  width: 100%;
  height: 100%;
}

.content-nav__arrow-icon-fill {
  fill: var(--primary-active);
}

.content-nav__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 35px;
}

.content-nav__list_offset {
  margin: 0 0 0 90px;
}

.content-nav__list-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.content-nav__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 900px) {
  .content-nav {
    padding-bottom: 12px;
    margin: 20px 0 8px;
  }

  .content-nav__title {
    font-size: 12px;
    font-weight: 750;
    line-height: 120%;
  }

  .content-nav__toggle-btn {
    width: 19px;
    height: 19px;
  }

  .content-nav__list {
    margin-top: 12px;
  }

  .content-nav__list_offset {
    margin: 0 0 0 20px;
  }

  .content-nav__text {
    font-size: 12px;
  }
}
