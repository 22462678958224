.partners-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 72px;
}

.partners-heading__title {
  max-width: 938px;
  text-align: center;
  font-size: 74px;
  font-style: normal;
  font-weight: 750;
  line-height: 119%;
}

.partners-heading__subtitle {
  max-width: 632px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 25px 32px;
}

.partners-heading__imgs-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  margin-top: 48px;
  z-index: 1;
}

.partners-heading__info-list {
  display: none;
}

.partners-heading__people-img {
  width: 39.37%;
  object-fit: contain;
  position: relative;
  left: 8.18%;
  user-select: none;
  pointer-events: none;
  z-index: 2;
}

.partners-heading__notebook-img {
  width: 48.59%;
  object-fit: contain;
  position: relative;
  right: 12.92%;
  user-select: none;
  pointer-events: none;
  z-index: 3;
}

.partners-heading__bg {
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 780px) {
  .partners-heading {
    padding-top: 42px;
  }

  .partners-heading__title {
    font-size: 28px;
  }

  .partners-heading__subtitle {
    font-size: 16px;
    padding: 16px 12px 0;
  }

  .partners-heading__imgs-block {
    margin-top: 44px;
  }
}

@media (max-width: 550px) {
  .partners-heading__imgs-block {
    flex-direction: column;
    align-items: center;
  }

  .partners-heading__info-list {
    display: flex;
    gap: 18px;
    position: relative;
    margin-right: 12px;
    z-index: 1;
  }

  .partners-heading__info-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .partners-heading__info-item:first-of-type {
    margin-top: 30px;
  }

  .partners-heading__card {
    width: 165px;
    height: 72px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 18px 0 0 15px;
  }

  .partners-heading__card-numbers {
    color: var(--primary-text);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    position: relative;
    z-index: 2;
  }

  .partners-heading__card-text {
    color: var(--primary-text);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-top: 4px;
    position: relative;
    z-index: 2;
  }

  .partners-heading__card-bg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .partners-heading__card_type_blue .partners-heading__card-bg {
    opacity: 0.75;
  }

  .partners-heading__card_type_red .partners-heading__card-bg {
    opacity: 0.75;
  }

  .partners-heading__card-icon {
    object-fit: contain;
    position: relative;
    z-index: 1;
  }

  .partners-heading__card_type_green .partners-heading__card-icon {
    width: 72px;
    height: 60px;
  }

  .partners-heading__card_type_blue .partners-heading__card-icon {
    width: 61px;
    height: 64px;
  }

  .partners-heading__card_type_yellow .partners-heading__card-icon {
    width: 51px;
    height: 56px;
  }

  .partners-heading__card_type_red .partners-heading__card-icon {
    width: 50px;
    height: 56px;
  }

  .partners-heading__card-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .partners-heading__card_type_green .partners-heading__card-gradient {
    width: 228px;
    background: linear-gradient(
      94deg,
      var(--partners-bg-gradient-green-first) 4.48%,
      var(--partners-bg-gradient-green-second) 96.56%
    );
  }

  .partners-heading__card_type_blue .partners-heading__card-gradient {
    background: linear-gradient(
      94deg,
      var(--partners-bg-gradient-blue-first) 4.48%,
      var(--partners-bg-gradient-blue-second) 96.56%
    );
  }

  .partners-heading__card_type_yellow .partners-heading__card-gradient {
    background: linear-gradient(
      93deg,
      var(--partners-bg-gradient-yellow-first) 3.06%,
      var(--partners-bg-gradient-yellow-second) 97.27%
    );
  }

  .partners-heading__card_type_red .partners-heading__card-gradient {
    background: linear-gradient(
      94deg,
      var(--partners-bg-gradient-red-first) 3.21%,
      var(--partners-bg-gradient-red-second) 102.04%
    );
  }

  .partners-heading__people-img {
    width: 103.85%;
    max-width: 450px;
    left: -3.85%;
    margin-top: -38px;
  }

  .partners-heading__notebook-img {
    display: none;
  }

  .partners-heading__bg {
    width: 1090px;
    left: -185px;
  }
}
