.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 80px 180px 80px;
    background-color: var(--website-background);
    width: 100%;
}

.partners__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 64px;
    margin-bottom: 115px;
}

.partners__logo_line-1 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 152px 106px 116px 116px;
    grid-template-areas: 'card1 card1 card1 card1 card1 card2 card2 card2 card2 card2 card2 card2'
        'card3 card3 card3 card4 card4 card4 card5 card5 card5 card6 card6 card6'
        'card7 card7 card7 card8 card8 card8 card9 card9 card9 card9 card10 card10'
        '. . card11 card11 card11 card11 card12 card12 card12 . . .'
    ;
    width: 100%;
    max-width: 1500px;
    column-gap: 98px;
    row-gap: 80px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.partners__logo_line-1__logo {
    width: 100%;
    object-fit: cover;
}

@media (max-width:1670px) {
    .partners__logo_line-1 {
        column-gap: 78px;
        row-gap: 60px;
        grid-template-areas: 'card1 card1 card1 card1 card1 card2 card2 card2 card2 card2 card2 card2'
            'card3 card3 card3 card4 card4 card4 card5 card5 card5 card6 card6 card6'
            'card7 card7 card7 card8 card8 card8 card9 card9 card9 card9 card10 card10'
            '. . card11 card11 card11 card11 card12 card12 card12 . . .'
        ;
    }
}

@media (max-width:1250px) {
    .partners__logo_line-1 {
        column-gap: 58px;
        row-gap: 60px;
        /* grid-template-areas: 'card1 card1 card1 card1 card1 card1 card1 card2 card2 card2 card2 card2 card2 card3 card3 card3 card3'
            '. card4 card4 card4 card4 card5 card5 card5 card5 card6 card6 card6 card6 card6 card7 card7 card7'
            'card8 card8 card8 card8 card8 card8 card9 card9 card9 card9 card9 card9 card10 card10 card10 card11 .'
        ; */
    }
}

@media (max-width:1200px) {
    .partners__title {
        margin-bottom: 80px;
    }

    .partners__logo_line-1 {
        column-gap: 50px;
        row-gap: 40px;
    }
}

@media (max-width:960px) {
    .partners {
        padding: 53px 22px 50px 22px;
    }

    .partners__title {
        margin-bottom: 30px;

        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 114.286% */
    }

    .partners__logo_line-1 {
        column-gap: 30px;
        row-gap: 30px;
        /* grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 50px 60px 33px 18px 34px 20px 36px;
        grid-template-areas: '. . card1 card1 card1 card1 card1 .'
        ' . card2 card2 card2 card2 card2 card2 card2'
        'card4 card4 . card5 card5 . card6 card6'
        ' card7 card7 . card8 card8 . card9 card9 '
        ' card13 card13 card13 card13 card14 card14 card14 card14'
        '. . card11 card11 card12 card12  . .'
        '.  card3 card3 card3 card10 card10 . .'
        ; */


    }
}

@media (max-width:800px) {
    .partners__logo_line-1 {
        column-gap: 20px;
        row-gap: 70px;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 50px 60px 33px 18px 20px 58px;
        grid-template-areas:
            ' . card2 card2 card2 card2 card2 card2 .'
            '. card1 card1 card1 card1 card1 card1 .'
            'card4 card4 . card5 card5 . card6 card6'
            ' card7 card7 . card8 card8 . card9 card9 '
            ' . card11 card11 card11 card12 card12 card12 .'
            '. . card3 card3 card10 card10  . .'

        ;
    }
}

@media (max-width:500px) {
    .partners__logo_line-1 {
        column-gap: 10px;
        row-gap: 40px;
    }
}