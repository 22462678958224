.reviews {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--website-secondary-background);
    padding: 80px 0 108px;
    overflow: hidden;
}

.reviews__content {
    box-sizing: border-box;
    width: 100%;

    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reviews__slides {

    width: 100% !important;
    overflow: hidden;
    padding-bottom: 50px !important;
}

.reviews__slide {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    /* max-width: 90%; */

    /* margin-right: 16px; */
    position: relative;
    /* margin-left: 30px; */

    overflow: hidden;


}

.reviews__slides__pagination .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
}

.reviews__slides__pagination .swiper-pagination-bullet-active {
    width: 22px;
    height: 22px;
}

.reviews__slides__pagination .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 35px;
}

@media (max-width: 880px) {
    .reviews__slides__pagination .swiper-pagination-bullet {
        width: 11.177px;
        height: 11.177px;
    }
    
    .reviews__slides__pagination .swiper-pagination-bullet-active {
        width: 15.368px;
        height: 15.368px;
    }
    
    .reviews__slides__pagination .swiper-pagination {
        display: flex;
        justify-content: center;
        gap: 24.45px;
        width: 100%;
    }
}

@media (max-width: 780px) {
    .reviews {
        padding: 50px 0 30px;
    }

    .reviews__slides {
        padding-bottom: 20px !important;
    }
}

@media (max-width:500px) {
    .reviews__slides__pagination .swiper-pagination-bullet {
        width: 11.177px;
        height: 11.177px;
    }
    
    .reviews__slides__pagination .swiper-pagination-bullet-active {
        width: 15.368px;
        height: 15.368px;
    }
    
    .reviews__slides__pagination .swiper-pagination {
        display: flex;
        justify-content: center;
        gap: 5px;
        width: 100%;
    }
}