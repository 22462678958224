.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 0 96px;
}

.article__container {
  width: 100%;
  max-width: 1900px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 80px;
}

.article__content {
  width: 100%;
  max-width: 1410px;
}

.article__title {
  width: 84%;
  max-width: 1080px;
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 750;
  line-height: 116.5%;
  margin-left: auto;
}

.article__box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 80px;
}

.article__side-likes {
  display: flex;
  flex-direction: column;
  gap: 28px;
  box-sizing: border-box;
  padding-top: 35px;
}

.article__like-item:first-of-type {
  margin-bottom: 32px;
}

.article__like-btn {
  display: flex;
  align-items: center;
  gap: 16px;
}

.article__like-btn_column {
  flex-direction: column;
}

.article__like-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.article__like-btn-box_column {
  flex-direction: column;
}

.article__like-icon {
  width: 78px;
  aspect-ratio: 1/1;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.article__like-icon_type_small {
  width: 72px;
}

.article__like-text {
  opacity: 0.5;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.article__block {
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
}

.article__divider-block {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--primary-active);
  box-sizing: border-box;
  padding: 32px 80px;
  margin-top: 80px;
}

.article__divider-text {
  color: var(--primary-text);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 150%;
}

.article__closure-block {
  width: 100%;
  max-width: 1640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 80px;
  margin-top: 62px;
}

.article__likes-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article__likes-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 750;
  line-height: 116.5%;
  padding-bottom: 16px;
}

.article__social-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.article__social-list {
  display: flex;
  gap: 52px;
}

.article__social-item:nth-child(2n) {
  margin-top: 20px;
}

.article__form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--article-border-color);
  box-sizing: border-box;
  padding-top: 58px;
  margin-top: 48px;
}

.article .blog__main-grid__like-box {
  gap: 10px;
}

.article__likes-block .blog__main-grid__like-box {
  flex-direction: column;
}

@media (max-width: 1200px) {
  .article__container {
    padding: 0 42px;
  }

  .article__title {
    width: 100%;
    max-width: unset;
    margin-left: 0;
  }

  .article__side-likes {
    display: none;
  }

  .article__divider-block {
    padding: 32px 42px;
  }

  .article__closure-block {
    padding: 0 42px;
  }
}

@media (max-width: 900px) {
  .article__container {
    padding: 0 20px;
  }

  .article__title {
    text-align: left;
    font-size: 22px;
  }

  .article__divider-block {
    padding: 32px 20px;
    margin-top: 28px;
  }

  .article__divider-text {
    text-align: left;
    font-size: 12px;
  }

  .article__closure-block {
    padding: 0 10px;
    margin-top: 28px;
  }

  .article__likes-title {
    font-size: 22px;
  }

  .article__like-btn {
    gap: 12px;
  }

  .article__like-btn-box {
    gap: 12px;
  }

  .article__like-icon_type_small {
    width: 66px;
  }

  .article__like-text {
    font-size: 16px;
  }

  .article__social-list {
    gap: 48px;
  }

  .article__form-block {
    padding-top: 58px;
    margin-top: 28px;
  }
}