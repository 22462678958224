.header-popup {
  background: var(--website-background);
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.header-popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.header-popup__box {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  position: relative;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
  transform: translateX(calc(-100%+55px));
  opacity: 0;
  z-index: 6;
  padding: 16px 16px 32px;
  box-sizing: border-box;
}

.header-popup_opened .header-popup__box {
  transform: translateX(0);
  opacity: 1;
}

.header-popup__heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-popup__logo-link {
  display: flex;
}

.header-popup__logo {
  width: 88px;
  height: 32px;
}

.header-popup__close {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  padding: 5px;
}

.header-popup__close-icon {
  width: 100%;
  height: 100%;
}

.header-popup__close-icon-fill {
  fill: var(--primary-active);
}

.header-popup__close-icon-stroke {
  stroke: var(--primary-active);
  stroke-width: 2px;
}

.header-popup__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0 4px;
  margin-top: 38px;
}

.header-popup__controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  box-sizing: border-box;
  padding-right: 5px;

  display: grid;
  grid-template-columns: 1fr 67px 28px;
}

.header-popup__login {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.header-popup__control {
  width: fit-content;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 47px;
  background-color: var(--website-secondary-background);
  box-sizing: border-box;
  padding: 6px;
}

.header-popup__theme-icon {
  width: 16px;
  height: 16px;
}

.header-popup__theme-icon-fill {
  fill: var(--neutral-active);
}

.header-popup__box-nav-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 46px;
  /* z-index: 2; */
}

.header-popup__box-nav-links-link {
  display: flex;
  align-items: center;
}

.header-popup__box-nav-links-link_active {
  color: var(--primary-active);
  pointer-events: none;
  user-select: none;
}

.header-popup__box-nav-links-link_type_disabled {
  pointer-events: none;
  user-select: none;
}

.header-popup__box-nav-links-link-txt {
  color: var(--neutral-active);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  transition: all 0.3s ease-in-out;
}

.header-popup__box-nav-links-link_active .header-popup__box-nav-links-link-txt {
  color: var(--primary-active);
}

.header-popup__box-nav-links-dot {
  width: 24px;
  height: 24px;
  align-self: center;
  background-color: var(--primary-active);
  border-radius: 50%;
  position: relative;
  z-index: 999999;
  visibility: hidden;
}

.header-popup__box-nav-links-link_type_active .header-popup__box-nav-links-dot {
  visibility: visible;
}

.header-popup__demo {
  box-shadow: 0px 17px 24px 0px rgba(9, 15, 72, 0.28);
  width: 100vw;
  box-sizing: border-box;
  padding: 0 16px;
  margin-left: -55px;
  margin-top: 40px;
}

.header-popup__box-contacts {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  align-items: flex-start;
  z-index: 2;
}

.header-popup__box-contacts-box {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.header-popup__box-contacts-box:hover {
  cursor: pointer;
  opacity: 0.7;
}

.header-popup__box-contacts-box-email,
.header-popup__box-contacts-box-number {
  color: var(--website-background);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

.header-popup__line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.17);
  z-index: 6;
  margin-top: 66px;
  opacity: 0;
}

.header-popup__line_type_second {
  transition: all 0.6s ease-in-out;
  transition-delay: 0.3s;
  height: 1px;
  transform: translateX(-100%);
}

.header-popup_opened .header-popup__line_type_second {
  opacity: 1;
  transform: translateX(0);
}

.header-popup__box-rhomb {
  position: absolute;
  z-index: 1;
}

.header-popup__box-rhomb_type_big {
  top: 34px;
  left: 0px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.5s;
  opacity: 0;
  transform: translateX(calc(-100%));
  width: 566px;
  height: 328px;
}

.header-popup__box-rhomb_type_small-dark {
  right: 0px;
  top: 107px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.5s;
  opacity: 0;
  transform: translateX(100%);
}

.header-popup__box-rhomb_type_small-light {
  right: 0px;
  top: 344px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.5s;
  opacity: 0;
  transform: translateX(100%);
}

.header-popup_opened .header-popup__box-rhomb_type_big {
  opacity: 1;
  transform: translateX(0);
}

.header-popup_opened .header-popup__box-rhomb_type_small-dark {
  opacity: 1;
  transform: translateX(0);
}

.header-popup_opened .header-popup__box-rhomb_type_small-light {
  opacity: 1;
  transform: translateX(0);
}
