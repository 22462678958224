.img-element {
  width: 100%;
  height: 490px;
  display: flex;
  margin-top: 35px;
}

.img-element__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

@media (max-width: 900px) {
  .img-element {
    height: 280px;
    margin-top: 20px;
  }

  .img-element__picture {
    border-radius: 20px;
  }
}

@media (max-width: 500px) {
  .img-element {
    height: 145px;
  }

  .img-element__picture {
    border-radius: 10px;
  }
}
