.promo-start {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background: var(--primary-active);
  box-sizing: border-box;
  padding: 32px 44px 38px 52px;
  margin-top: 12px;
}

.promo-start__logo {
  width: 164px;
  height: 63px;
  position: relative;
  z-index: 2;
}

.promo-start__logo-fill {
  fill: var(--primary-text);
}

.promo-start__text {
  color: var(--primary-text);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.promo-start__btn {
  min-width: 226px;
  color: var(--primary-text);
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  mix-blend-mode: screen;
  border-radius: 71px;
  border: 7px solid var(--primary-text);
  box-sizing: border-box;
  padding: 24px 77px 17px;
  position: relative;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.promo-start__btn::before {
  content: "";
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  position: absolute;
  top: -7px;
  left: -7px;
  background: var(--primary-text);
  border-radius: 71px;
  mix-blend-mode: color-burn;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.promo-start__btn:hover {
  color: var(--neutral-active);
}

.promo-start__btn:hover::before {
  opacity: 1;
}

.promo-start__circle {
  width: 273px;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  background: var(--article-circle-first-color);
  filter: blur(56px);
  z-index: 0;
}

.promo-start__circle_type_top-right {
  top: -136px;
  right: -70px;
}

.promo-start__circle_type_bottom-left {
  background: var(--article-circle-secondary-color);
  bottom: -211px;
  right: -43px;
  z-index: 1;
}

.promo-start__circle_type_bottom-right {
  bottom: -168px;
  right: 210px;
}

@container (max-width: 1020px) {
  .promo-start {
    flex-wrap: wrap;
    gap: 20px;
  }

  .promo-start__logo {
    margin-left: 72px;
  }

  .promo-start__text {
    text-align: center;
    margin-right: 72px;
  }

  .promo-start__btn {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .promo-start {
    flex-wrap: nowrap;
    gap: 20px;
    padding: 17px 26px 24px;
  }

  .promo-start__logo {
    width: 89px;
    height: 34px;
    margin-left: 0;
  }

  .promo-start__text {
    font-size: 26px;
    text-align: left;
    margin-right: 0;
  }

  .promo-start__btn {
    width: 40%;
    font-size: 20px;
    border-radius: 38px;
    border-width: 4px;
    box-sizing: border-box;
    padding: 13px 32px 9px;
  }

  .promo-start__btn:hover {
    color: var(--primary-text);
  }

  .promo-start__btn:hover::before {
    opacity: 0;
  }

  .promo-start__circle {
    width: 348px;
    filter: blur(72px);
  }

  .promo-start__circle_type_top-right {
    display: none;
  }

  .promo-start__circle_type_bottom-left {
    width: 198px;
    bottom: -120px;
    left: -73px;
    filter: blur(41px);
  }

  .promo-start__circle_type_bottom-right {
    bottom: -181px;
    right: -206px;
  }
}

@media (max-width: 670px) {
  .promo-start {
    flex-wrap: wrap;
    gap: 20px;
  }

  .promo-start__logo {
    margin-left: 52px;
  }

  .promo-start__text {
    text-align: center;
    margin-right: 52px;
  }

  .promo-start__btn {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .promo-start {
    flex-direction: column;
    align-items: flex-start;
  }

  .promo-start__logo {
    margin-left: 0;
  }

  .promo-start__text {
    text-align: left;
    margin-right: 0;
  }

  .promo-start__btn {
    width: 76%;
  }
}
