.main-big-card {
  width: 100%;
  height: 100%;
  display: flex;
}

.main-big-card__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}